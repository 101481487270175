export function scrollToElement(elementSelector) {
  const element = document.querySelector(elementSelector);

  if (element) {
    const top = element.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top,
      behavior: "smooth",
    });
  }
}

export function topoFloating() {
  const header = document.querySelector(".header-floating");
  const element = document.querySelector(".section__intro");
  const main = document.querySelector("#main");
  const blockOne = document.querySelector(".section__start");
  const blockTwo = document.querySelector(".section__termos");

  if (
    element?.clientHeight &&
    main?.clientHeight &&
    blockOne?.clientHeight &&
    blockTwo?.clientHeight &&
    header
  ) {
    if (
      window.scrollY >= element.clientHeight - 100 &&
      window.scrollY <
        main.clientHeight -
          (blockOne.clientHeight + blockTwo.clientHeight + 150)
    ) {
      header.classList.add("fixed");
    } else {
      header.classList.remove("fixed");
    }
  }
}

export function handleScroll() {
  topoFloating();

  const intro = document.querySelector(".section__intro");

  if (
    intro &&
    !isCounterTriggered &&
    window.scrollY >= intro.clientHeight - 350
  ) {
    counter(() => {});
  }
}

let isCounterTriggered = false;

export function counter(onComplete) {
  if (isCounterTriggered) {
    return;
  }

  const count = document.querySelectorAll(".counter-number");

  count.forEach((itens) => {
    let i = parseInt(itens.getAttribute("data-from-value"));
    let value = parseInt(itens.getAttribute("data-to-value"));
    let delay = parseInt(itens.getAttribute("data-duration"));

    let loop = setInterval(() => {
      itens.innerText = `${i}`;
      if (i >= value) {
        clearInterval(loop);
        isCounterTriggered = true;
        if (typeof onComplete === "function") {
          onComplete();
        }
      } else {
        i++;
      }
    }, delay);
  });
}
