import { useEffect, useState } from "react";

/**
 * A custom hook that returns a boolean value and a function to close a component. 
 * It checks whether an item identified by a unique item name is closed in the local storage or not. 
 * @param {string} uniqueItemName A unique name to identify the item in the local storage.
 * @param {boolean} defaultData A default value for the isClosed state.
 * @returns {Array} An array containing a boolean value indicating whether the component is closed or not, and a function to close the component.
 */
export function useCloseable(uniqueItemName, defaultData) {
  const [ isClosed, setIsClosed ] = useState(true);

  function closeMethod() {
    localStorage.setItem(uniqueItemName, "closed");
    setIsClosed(true);
  }

  useEffect(() => {
    const itemIsNotClosed = !localStorage.getItem(uniqueItemName);
    if(itemIsNotClosed) setIsClosed(defaultData);
  }, []);


  return [ isClosed, closeMethod ];
}