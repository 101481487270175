import image from "../../assets/img/content/campaign-ads-4.png";
import Ballons from "./components/Ballons";

import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

const CampaingCreationCallToAction = ({ isInHome=true }) => {

  return (
    <div className={ `${styles.campaing_creation_call_to_action_container} ${isInHome ? styles.is_in_home : ""}` }>
      
      <div className={ styles.main_container }>
        <div className={ styles.container }>

          <img
            alt=""
            aria-hidden="true"
            className={ styles.image }
            src={ image }
          />

          <div className={ styles.text }>
            <h3 className={ styles.title }>
              É hora de dar o próximo passo e criar sua campanha Performance Max.
            </h3>

            <strong className={ styles.subtitle }>
              Queremos facilitar esse processo para você.
            </strong>

            <p className={ styles.description }>
              Estamos aqui para ajudar a maximizar seus resultados e alcançar seu público-alvo de maneira eficaz. 
              Vamos criar algo incrível juntos!
            </p>

            <Link  
              className={ styles.button }
              to="/pmax"
            >
              Criar Campanha
            </Link>
          </div>

        </div>
        <Ballons isInHome={ isInHome }/>
      </div>


    </div>
  )
}

export default CampaingCreationCallToAction;