import { Button } from "../Button";
import { Modal } from "../Modal";
import CheckImg from "../../assets/img/content/check.png";

import "./styles.scss"

export function WelcomeModal({ isOpen, closeModal }) {
    return (
        <Modal isOpen={isOpen} closeModal={closeModal}>
            <div className="welcome-title">
                <p>Parabéns, suas configurações foram finalizadas!</p>
                <p className="close" onClick={closeModal}>x</p>
            </div>
            <div className="welcome-body">
                <div className="text-center">
                    <img src={CheckImg} alt="Check" className="check" />
                </div>
                <p>Agora você poderá acompanhar as estatísticas da sua loja aqui no Painel Google.</p>
                <p>Estamos fazendo os últimos ajustes. <br /> Alguns dados e produtos podem ainda não estar sincronizados.</p>
                <p>Fique tranquilo, em breve o seu painel estará completo.</p>
            </div>
            <div className="welcome-footer">
                <div className="widget__connect__buttons">
                    <Button onClick={closeModal}>
                        Conhecer o painel Google
                    </Button>
                </div>
            </div>
        </Modal>
    )
}