import { useCloseable } from "../../hooks/useIsClosable";
import { useEffect, useState } from "react";

import Control from "./components/Control";
import { IoClose } from "react-icons/io5";

import styles from "./styles.module.scss";
import Item from "./components/Item";

import { Modal } from "../Modal";

const CampaignCarousel = () => {
  const imageBase = "campaign-ads";
  const [currentOpen, setCurrentOpen] = useState(0);

  const [isClose, setIsClose] = useCloseable("traygle-campaign-carousel-ads", false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    return () => {
      setIsClose(false);
    };
  }, []);

  useEffect(() => {
    setIsOpen(isClose ? false : true);
  }, [isClose]);

  const close = () => setIsClose(true);

  const data = [
    {
      title: "🎉 Parabéns! Sua integração foi concluída com sucesso.",
      subtitle: "Agora seus produtos estão prontos para serem integrados ao Google Ads.",
      text: "Esta conquista merece ser celebrada, pois marca o início de uma nova fase para o seu negócio. Estamos empolgados por ter você conosco nessa jornada e mal podemos esperar para ver os frutos dessa parceria.",
    },
    {
      title: "Uma experiência ainda mais especial para novos anunciantes no Google Ads.",
      subtitle: "Gaste R$ 1.200,00 em até 60 dias e receba R$ 1.200,00 de volta na conta do Google Ads.",
      text: "Uma oportunidade única para impulsionar seus resultados e aumentar sua visibilidade online. Lembre-se de que o algoritmo leva cerca de 14 dias para atingir seu desempenho, então comece agora e aproveite ao máximo essa oferta incrível.",
      link: "https://www.google.com/intl/pt-br_br/ads/coupons/terms/",
      linkText: "Termos e Condições da oferta",
    },
    {
      title: "Você não está sozinho nessa jornada!",
      subtitle: "Queremos compartilhar um case de sucesso que ilustra como a YoSports obteve benefícios significativos ao integrar seus produtos ao Google Ads.",
      text: "A experiência deles pode inspirar e guiar você nesse novo capítulo. Descubra como eles alcançaram novos patamares de visibilidade e sucesso com a nossa plataforma.",
      link: "https://www.tray.com.br/yosport/?utm_source=site&utm_medium=dobra-google&utm_campaign=case-yosport&utm_term=dobra-exclusiva-yosport",
      linkText: "Ver case YoSports",
    },
    {
      title: "É hora de dar o próximo passo e criar sua campanha Performance Max.",
      subtitle: "Queremos facilitar esse processo para você.",
      text: "Estamos aqui para ajudar a maximizar seus resultados e alcançar seu público-alvo de maneira eficaz. Vamos criar algo incrível juntos!",
    },
  ];

  return (
    <Modal contentBg="transparent" contentWidth="95%" isOpen={isOpen} maxWidth={750} closeModal={close}>
      <div className={styles.carousel_campaign_container}>
        {/* <IoClose 
          className={ styles.close_icon }
          size={ 30 }
          onClick={ () => close() }
        /> */}

        <div className={styles.main_container}>
          {data.map((item, index) => (
            <Item
              open={index === currentOpen}
              imageSrc={require(`../../assets/img/content/${imageBase}-${index + 1}.png`).default}
              title={item.title}
              subtitle={item.subtitle}
              text={item.text}
              link={item.link}
              linkText={item.linkText}
              key={item.title}
            />
          ))}

          <Control currentOpen={currentOpen} setCurrentOpen={setCurrentOpen} itemsQuantity={data.length} />
        </div>
      </div>
    </Modal>
  );
};

export default CampaignCarousel;
