import { api } from "../services/api";

export async function getCampaign(storeId) {
  try {
    const res = await api.get(`platforms/tray/stores/${storeId}`);
    return res.data.google?.campaignId || "";
  } catch (err) {
    return "";
  }
}

export async function checkIfHasCampaignCreated(auth) {
  try {
    const [responseGetCampaigns, responseHasCreatedCampaign] = await Promise.all([
      api.get(`google/ads/customers/${auth.googleAdsAccount?.id}/campaigns?type=${auth.campaignType}`),
      api.post(`google/ads/customers/has_created_campaigns`, {
        storeId: auth.store.id,
      }),
    ]);
    return responseGetCampaigns.data.length > 0 || responseHasCreatedCampaign.data;
  } catch (err) {
    console.log(err);
  }
}
