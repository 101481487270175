import { useState } from "react";
import { toast } from "react-toastify";

import { Button } from "../../components/Button";
import { useAuth } from "../../hooks/useAuth";
import { useWizard } from "../../hooks/useWizard";
import { wizardSteps } from "../../contexts/WizardContext"
import { api } from "../../services/api";

import TipsImg from "../../assets/img/icons/tips.svg";
import { Tooltip } from "../Tooltip";

export function NoAccount() {
  const auth = useAuth();
  const wizard = useWizard();

  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [codeCheckbox, setCodeCheckbox] = useState(false);
  const [termsCheckbox, setTermsCheckbox] = useState(false);

  async function handleCreateAccount() {
    try {
      setIsCreatingAccount(true);

      const response = await api.post("google/ads/customers");
      auth.changeGoogleAdsAccount({
        id: response.data.id,
        name: response.data.name,
        isNew: true
      });

      await api.post(
        `google/ads/customers/${response.data.id}/merchant_center_link`
      );

      await api.post(
        `google/ads/customers/${response.data.id}/set_gtag`
      );

      toast.success(`Conta Google Ads criada com sucesso.`);

      wizard.updateCurrentStep(wizardSteps.ACCOUNTS_CONNECTION_SUCCESS);
    } catch (err) {
    } finally {
      auth.setIsNewGoogleAdsAccountCreated(true);
      setIsCreatingAccount(false);
    }
  }

  return (
    <div className="widget-products">
      <h1 className="widget-products__title">Criar uma Conta Google Ads.</h1>
      <p className="widget-products__text">
        Agora vamos criar a sua conta Google Ads. <br />
        Ela permite que você crie {auth.campaignTypeNamePlural} no Google.
      </p>

      <p className="widget-products__description">
        O Google Ads tem como requisito a instalação de tags que irão rastrear
        as vendas do seu site e também criar listas de remarketing. <br />
        <br />A Tray vai fazer isso automaticamente por você, mas precisamos que
        conceda estas permissões e aceite os termos:
      </p>

      <div className="widget-products__confirm">
        <label className="control control--checkbox">
          <input
            type="checkbox"
            id="confirm"
            name="confirm"
            className="confirmed"
            checked={codeCheckbox}
            onChange={() => {
              setCodeCheckbox((oldVersion) => !oldVersion);
            }}
          />
          <div className="control__indicator"></div>
          <span>
            Concordo em instalar códigos que irão rastrear e integrar o meu
            site.
            <Tooltip id="codes" message="Estes códigos serão utilizados para melhorar suas campanhas e medir o resultado em vendas">
              <img src={TipsImg} alt="Dicas" />
            </Tooltip>
          </span>
        </label>

        <label className="control control--checkbox">
          <input
            type="checkbox"
            id="confirm2"
            name="confirm2"
            className="confirmed"
            checked={termsCheckbox}
            onChange={() => {
              setTermsCheckbox((oldVersion) => !oldVersion);
            }}
          />
          <div className="control__indicator"></div>
          <span>
            Ao contratar anúncios do Shopping, você concorda em cumprir os Termos e Políticas do Google, incluindo as{" "}
            <a target="_blank" href="https://support.google.com/merchants/answer/6149970?hl=pt-br">Políticas de Anúncios do Shopping</a> e os{" "}
            <a target="_blank" href="https://billing.google.com/payments/u/0/paymentsinfofinder?style=:md#">Termos e Condições do Google Ads</a>.
          </span>
          <Tooltip id="terms" message="Ao marcar que aceita você estará concordando com os termos dos serviços informados">
            <img src={TipsImg} alt="Dicas" />
          </Tooltip>
        </label>
      </div>

      <div className="widget-products__buttons">
        <Button
          disabled={!codeCheckbox || !termsCheckbox}
          isSubmitting={isCreatingAccount}
          onClick={handleCreateAccount}
          submitingMessage="Criando conta"
        >
          Criar conta Google Ads
        </Button>
      </div>
    </div>
  );
}
