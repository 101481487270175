import { useEffect, useState } from "react";
import { api } from "../../services/api";
import { Pagination } from "../../components/Pagination";
import { BsExclamationTriangle, BsExclamationCircle, BsLink45Deg } from "react-icons/bs";
import { useAuth } from "../../hooks/useAuth";
import SpinnerBlueSvgGif from "../../assets/img/icons/spinner-blue.svg";
import NotFoundSvg from "../../assets/img/icons/Not found.svg";
import ExternalLink from "../../assets/img/icons/external-link.svg";
import { AiOutlineSearch } from "react-icons/ai";
import styles from "./styles.module.scss";

function GroupProductProblems({ productProblemsProps }) {
  const auth = useAuth();

  const getIcon = (status) => {
    switch (status) {
      case "suggestion":
      case "unaffected":
        return <BsExclamationCircle size={13} color="#1ABC9C" />;
      case "error":
      case "demoted":
        return <BsExclamationTriangle size={13} color="#F7B84B" />;
      case "critical":
      case "disapproved":
        return <BsExclamationCircle size={13} color="#F1556C" />;
      default:
        break;
    }
  };

  const [productProblems, setProductProblems] = useState([]);
  const [itemLevelIssues, setItemLevelIssues] = useState(productProblemsProps?.itemLevelIssues || []);

  const [displayedProblems, setDisplayedProblems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState("");

  const [searchTerm, setSearchTerm] = useState(null);
  const [isSearchRequest, setIsSearchRequest] = useState(false);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [lastPage, setLastPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  useEffect(() => {
    async function fetchData() {
      await setProductProblems(itemLevelIssues);
      await setCountry(productProblemsProps?.country || "");
      await setDisplayedProblems(itemLevelIssues.slice(startIndex, endIndex));
      await setTotalItems(itemLevelIssues.length);
      await setTotalProducts(auth?.productsStats?.total || 0);
      await setLoading(false);
    }
    fetchData();
  }, [productProblemsProps]);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  function checkIfHasNextPage() {
    if (isSearchRequest) {
      setHasNextPage(productProblems?.length >= perPage);
      return;
    }
    setHasNextPage(currentPage < lastPage);
  }

  useEffect(() => {
    checkIfHasNextPage();
  }, [productProblems]);

  // in case of changing page or perpage
  useEffect(() => {
    let startIndex = (currentPage - 1) * perPage;
    let endIndex = startIndex + perPage;
    setDisplayedProblems(productProblems.slice(startIndex, endIndex));
  }, [currentPage, perPage]);

  useEffect(() => {
    if (searchTerm && searchTerm !== "") {
      const filteredProblems = displayedProblems.filter((problem) => problem.description.toLowerCase().includes(searchTerm.toLowerCase()));
      setDisplayedProblems(filteredProblems);
    } else {
      if (itemLevelIssues) {
        setDisplayedProblems(itemLevelIssues.slice(startIndex, endIndex));
      }
    }
  }, [searchTerm]);

  return (
    <section class="reports__products">
      <div className="header">
        <section>
          <div className="input-control">
            <input type="text" placeholder="Busque pelo título do problema" value={searchTerm} onChange={(event) => setSearchTerm(event.target.value)} />

            <button onClick={() => {}}>
              <AiOutlineSearch className="mr-0.5" />
              Buscar
            </button>
          </div>
        </section>
      </div>

      <main className={styles.mainProductProblems}>
        {loading ? (
          <div className="text-align-center">
            <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
          </div>
        ) : productProblems.length === 0 ? (
          <div className={styles.notFoundWrapper}>
            <img src={NotFoundSvg} alt="Not found spinner" />
            <h4>Nenhum problema encontrado</h4>
          </div>
        ) : (
          <>
            <table className={styles.tableProblemsProduct}>
              <thead>
                <tr>
                  <th>Problema</th>
                  <th>Países</th>
                  <th style={{ minWidth: 215 }}>Produtos afetados</th>
                  <th>% afetados</th>
                </tr>
              </thead>

              <tbody>
                {displayedProblems &&
                  displayedProblems.length > 0 &&
                  displayedProblems.map((problem, index) => (
                    <tr>
                      <td>
                        {getIcon(problem.servability)} {problem.description}
                      </td>
                      <td>{country === "BR" ? "Brasil" : country}</td>
                      <td>
                        {problem.numItems}{" "}
                        {auth?.merchantAccount?.id && (
                          <a
                            target="_blank"
                            href={`https://merchants.google.com/mc/products/diagnostics?a=${auth?.merchantAccount?.id}&tab=item_issues`}
                            rel="noreferrer"
                            style={{ marginLeft: "10px" }}
                          >
                            ver produtos <img src={ExternalLink} alt="External link" />
                          </a>
                        )}
                      </td>
                      <td>{problem.numItems && totalProducts > 0 ? "< " + Math.ceil((problem.numItems / totalProducts) * 100) : 0}%</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {totalItems === 0 && (
              <div className="text-align-center">
                <p>Não existem produtos a serem exibidos.</p>
              </div>
            )}
          </>
        )}
        <section className="pagination">
          <div className="select-container">
            <p>Exibir</p>
            <select name="limit" value={perPage} onChange={(e) => setPerPage(parseInt(e.target.value))}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
            {!isSearchRequest && (
              <p className="total-itens">
                {currentPage * perPage - perPage + 1} a {currentPage * perPage > totalItems ? totalItems : currentPage * perPage} de {totalItems}
              </p>
            )}
          </div>
          <Pagination
            hasNextPage={currentPage < Math.ceil(totalItems / perPage)}
            currentPage={currentPage}
            lastPage={Math.ceil(totalItems / perPage)}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            showFirstAndLastPageArrows={false}
          />
        </section>
      </main>
    </section>
  );
}

export default GroupProductProblems;
