import axios from "axios";
import nookies from "nookies";

export const api = axios.create({
  baseURL: "https://api.merchant.tray.com.br/v1/",
  // baseURL: "http://localhost:5001/tray-d4b7d/us-central1/main/v1/",
});

export function setHeaderToken(token) {
  api.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
  };
}

// Add a response interceptor
api.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    // In case we have an error that has a redirectUrl, redirect the user
    const redirectUrl = error.response?.data?.error?.redirectUrl;
    if (redirectUrl) {
      window.top.location.href = redirectUrl;
    }

    // add token if missing and resend request
    if (error.response.status === 401) {
      const token = nookies.get(null)[`@Traygle:token`];
      const originalConfig = error.config;

      if (!originalConfig.headers["Authorization"] && token.length > 0) {
        originalConfig.headers["Authorization"] = `Bearer ${token}`;
        return Promise.resolve(api(originalConfig));
      }
    }

    return Promise.reject(error);
  }
);
