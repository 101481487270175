import { FaExternalLinkAlt } from 'react-icons/fa';
import styles from './styles.module.scss';

const Item = ({ imageSrc, title, subtitle, text, link, linkText, open }) => {
  if (!open) return <></>;

  return (
    <div className={styles.container}>
      <img alt='' aria-hidden='true' className={styles.image} src={imageSrc} />
      <div className={styles.text}>
        <h3 className={styles.title}>{title}</h3>
        <strong className={styles.subtitle}>{subtitle}</strong>
        <p className={styles.description}>{text}</p>
        {link && (
          <a
            className={styles.button}
            href={link}
            target='_blank'
            rel='noreferrer'
          >
            {linkText}
            <FaExternalLinkAlt color='#fff' size={16} />
          </a>
        )}
      </div>
    </div>
  );
};

export default Item;
