import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import { toast } from "react-toastify";
import { GoToCreateCampaignPage } from "../../../utils/goToCreateCampaignPage";

import { ProgressIndicator } from "../../../components/Wizard/ProgressIndicator";
import { Button } from "../../../components/Button";
import { useAuth } from "../../../hooks/useAuth";
import { useWizard } from "../../../hooks/useWizard";
import { wizardSteps } from "../../../contexts/WizardContext";
import { api } from "../../../services/api";

import SpinnerBlueSvgGif from "../../../assets/img/icons/spinner-blue.svg";
import { ExistingCampaignModal } from "../../../components/CreateCampaignPage/ExistingCampaignModal";

import styles from "./styles.module.scss";
import InvestmentSection from "../../../components/EditCampaign/InvestmentSection";
import { BsExclamationCircleFill } from "react-icons/bs";

export default function CreateCampaignPage() {
  const history = useHistory();
  const auth = useAuth();
  const wizard = useWizard();

  const [isBillingApproved, setIsBillingApproved] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [invitationLink, setInvitationLink] = useState("");
  const [loading, setLoading] = useState(true);

  const [existingCampaign, setExistingCampaign] = useState({});
  const [showExistingCampaignModal, setShowExistingCampaignModal] = useState(false);

  const [campaignData, setCampaignData] = useState({
    dailyAmountInCents: 3500,
  });

  useEffect(async () => {
    setLoading(true);

    getCampaigns().then((campaigns) => {
      if (campaigns && campaigns[0] && campaigns[0].campaign) {
        setExistingCampaign(campaigns[0].campaign);
        setShowExistingCampaignModal(true);
      }
    });

    const storeResponse = await api.get(`platforms/tray/stores/${auth.store.id}`);

    await checkIfBillingIsApproved().then((isApproved) => {
      setIsBillingApproved(isApproved);
    });

    setInvitationLink(storeResponse?.data?.google?.googleAdsInvitationLink);
    setLoading(false);
  }, []);

  const getCampaigns = async () => {
    const response = await api.get(`google/ads/customers/${auth.googleAdsAccount?.id}/campaigns?type=${auth.campaignType}`);
    return response?.data;
  };

  const checkIfBillingIsApproved = async () => {
    const response = await api.get(`google/ads/customers/${auth.googleAdsAccount?.id}/billing_setup`);

    return response?.data?.some((b) => b?.billingSetup?.status === "APPROVED" || b?.billingSetup?.status === "APPROVED_HELD");
  };

  async function getInvitationPending(auth, googleAdsCustomerId) {
    const res = await api.get(`platforms/tray/stores/${auth.store.id}/ads-invitation/${googleAdsCustomerId}?status=PENDING`);
    return res.data;
  }

  async function getInvitationStatus(auth, googleAdsCustomerId) {
    const res = await api.get(`platforms/tray/stores/${auth.store.id}/ads-invitation/${googleAdsCustomerId}`);
    const invitationData = res.data;

    if (!invitationData.results) return; // Return if invitation is accepted
    const lastInvitation = invitationData.results.length - 1;

    if (invitationData.results[lastInvitation].invitationStatus === "PENDING") return invitationData.results[lastInvitation].customerUserAccessInvitation;
    const resPending = await getInvitationPending(auth, googleAdsCustomerId);

    if (resPending.results && resPending.results[0]) return resPending.results[0].customerUserAccessInvitation;
    return invitationData.results[lastInvitation].customerUserAccessInvitation;
  }

  async function getCustomerId(storeId) {
    try {
      const res = await api.get(`platforms/tray/stores/${storeId}`);
      return res.data.google?.googleAdsCustomerId;
    } catch (err) {
      return "";
    }
  }

  async function handleOpenAndListenInvitationLink() {
    setIsSubmitting(true);
    await GoToCreateCampaignPage(() => goToNextPage(auth, campaignData), auth)
  }

  async function goToNextPage(authData, campaignDataParam) {
    setIsBillingApproved(true);
    setIsSubmitting(false);
    await createSmartShoppingCampaign(authData, campaignDataParam);
  }

  const openAndListenToInvitationLink = async (auth) => {
    setIsSubmitting(true);

    let adsWindowUrl = invitationLink || "https://ads.google.com/aw/billing/summary";
    const googleAdsCustomerId = await getCustomerId(auth.store.id);

    const adsWindow = showGoogleBillingPopUp(adsWindowUrl);

    let currentAttempt = 0;
    const maxAttempts = 360; // 360 attempts x 5 seconds = 30 minutes

    const interval = setInterval(async () => {
      if (currentAttempt > maxAttempts) {
        setIsSubmitting(false);
        clearInterval(interval);
      }

      const isApproved = await checkIfBillingIsApproved();

      // Customer accept the invitation link
      if (!isApproved) {
        adsWindow.close();
        setIsBillingApproved(true);
        setIsSubmitting(false);
        clearInterval(interval);

        createSmartShoppingCampaign();
      }

      currentAttempt += 1;
    }, 5000);
  };

  function showGoogleBillingPopUp(url) {
    // Calculate popUp size
    var h = Math.max(800, window.screen.availHeight * 0.66); // try to use 66% of height, but no smaller than 800
    var w = Math.max(500, window.screen.availWidth * 0.5); // try to use 25% of width, but no smaller than 800

    // Find popUp center
    var windowLocation = {
      left: window.screen.availLeft + window.screen.availWidth / 2 - w / 2,
      top: window.screen.availTop + window.screen.availHeight / 2 - h / 2,
    };

    const config =
      "ModalPopUp" +
      ", toolbar=no" +
      ", scrollbars=no," +
      ", location=no" +
      ", statusbar=no" +
      ", menubar=no" +
      ", resizable=0" +
      ", width=" +
      w +
      ", height=" +
      h +
      ", left=" +
      windowLocation.left +
      ", top=" +
      windowLocation.top;

    return window.open(url, "Billing", config);
  }

  const createSmartShoppingCampaign = async () => {
    try {
      setIsSubmitting(true);

      await api.post(`google/ads/customers/${auth.googleAdsAccount?.id}/campaigns`, { dailyAmountInCents: campaignData.dailyAmountInCents });

      auth.loadUser().then(() => {
        toast.success(`Campanha criada com sucesso.`);

        wizard.updateCurrentStep(wizardSteps.COMPLETED);
        history.push({ pathname: "/", state: { showWelcomeModal: true } });
      });
    } catch (err) {
      console.log(err);

      if (err?.response?.data?.error?.code === "4105") {
        toast.error(`O nome da campanha é obrigatório.`);
      }

      if (err?.response?.data?.error?.code === "4107") {
        toast.error(`Já existe uma campanha cadastrada com este nome. Por favor, escolha um nome diferente.`);
      }

      if (err?.response?.data?.error?.code === "4113") {
        toast.error("Não foi possível criar uma campanha de Maior Desempenho na conta Google Ads selecionada.");
      }

      if (err?.response?.data?.error?.code === "4117") {
        toast.error(`Não foi possível criar a campanha. Verifique se o usuário do email ${auth.primaryEmail} possui permissão para criar campanhas na conta Google Ads ${auth.googleAdsAccount?.id}.`);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTopUpLater = () => {
    wizard.updateCurrentStep(wizardSteps.COMPLETED);
    history.push({ pathname: "/", state: { showWelcomeModal: true } });
  };

  return (
    <>
      <Helmet>
        <title>Smart Shopping - Traygle</title>
        <meta name="description" content="Vamos promover seus produtos da melhor forma, nos melhores lugares." />
        <meta property="og:title" content="Smart Shopping - Traygle" />
        <meta property="og:description" content="Vamos promover seus produtos da melhor forma, nos melhores lugares." />
      </Helmet>

      <div className="campaign">
        <ProgressIndicator currentStep={5} />

        <div className="smart-shopping smart-shopping--campaign">
          <h1 className="smart-shopping__title smart-shopping__title--campaign">Agora sim, vamos criar sua campanha!</h1>
          <p className="widget-products__text">Defina um valor para sua campanha e leve seus produtos para mais pessoas, aumentando seu desempenho e suas vendas!</p>

          <div className={styles.investmentSection}>
            <InvestmentSection campaign={campaignData} changeCampaignData={(values) => setCampaignData((old) => ({ ...old, ...values }))} />
          </div>

          {loading ? (
            <div className="text-align-center">
              <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
            </div>
          ) : (
            <>
              <div className="account__form__item account__form__item--buttons">
                {isBillingApproved ? (
                  <Button isSubmitting={isSubmitting} 
                    submitingMessage="Criando campanha" onClick={createSmartShoppingCampaign}>
                    Criar campanha
                  </Button>
                ) : (
                  <Button isSubmitting={isSubmitting} submitingMessage="Aguardando informações de pagamento" onClick={handleOpenAndListenInvitationLink}>
                    Colocar saldo na campanha
                  </Button>
                )}
              </div>
              {!isBillingApproved && (
                <>
                  <p className="campaign__invest__content">* Será aberta uma nova aba dentro da plataforma do Google.</p>
                </>
              )}
              <hr />
              {!isBillingApproved && (
                <p className="text-align-center">
                  <Button className={styles.topUpLaterButton} onClick={() => handleTopUpLater()}>
                    Colocar saldo depois
                  </Button>
                </p>
              )}
              <div className={styles.disclaimer}>
                <BsExclamationCircleFill size={16} color="#008FFB" />
                <h3>Invista R$1.200. Receba R$1.200.</h3>
                <p>
                  Oferta disponível apenas para novos anunciantes com endereço de cobrança no Brasil. Receba R$1.200 em créditos para anúncios ao investir seus primeiros R$1.200 no Google Ads dentro
                  de 60 dias.*&nbsp;
                  <a href="https://www.google.com/ads/coupons/terms/" target="_blank">
                    Veja os termos e condições dessa oferta
                  </a>
                </p>
              </div>
            </>
          )}
        </div>
      </div>

      <ExistingCampaignModal campaign={existingCampaign} setShowExistingCampaignModal={setShowExistingCampaignModal} showExistingCampaignModal={showExistingCampaignModal} />
    </>
  );
}
