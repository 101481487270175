/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";

import { UpdateAddress } from "../UpdateAddress";
// import editIcon from "../../assets/img/icons/edit.svg";
import { useAuth } from "../../hooks/useAuth";
import { GoLinkExternal } from "react-icons/go";

import TipsImg from "../../assets/img/icons/tips.svg";
import GoogleImg from "../../assets/img/content/google.png";
import SmallCheckImg from "../../assets/img/icons/small-check.svg";
import MerchantImg from "../../assets/img/content/merchant.png";
import GoogleAdImg from "../../assets/img/content/google-ad.png";

import styles from "./styles.module.scss";

import { formatPhoneNumber } from "../../utils/format";
import { Tooltip } from "../Tooltip";
import LogsModal from "../LogsModal";
import { DisconnectAccountsButtonWithResonForm } from "../DisconnectAccountsButtonWithReasonForm/index";

function GoogleAccountsSection() {
    const auth = useAuth();

    const [address, setAddress] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setAddress({
            street: auth?.store?.address.street,
            postalCode: auth?.store?.address.postalCode,
            city: auth?.store?.address.city,
            state: auth?.store?.address.state
        })
    }, [auth.store]);

    return (
        <>
            <LogsModal
                handleClose={() => setIsModalOpen(!isModalOpen)}
                isOpen={isModalOpen}
                scope='store'
            />

            <section class="cell small-12 settings__account--section">
                <strong>
                    Contas Google
                </strong>

                {/* <div class="settings__account--section__title">
                    <h3>Contas Google</h3>
                    <button onClick={() => setIsModalOpen(true)} class="settings__button changes">Registro de alterações</button>
                </div> */}

                <div class="account">
                    <div class="grid-x account__content--container">
                        {auth?.primaryEmail && (
                            <div class="cell small-12 large-6">
                                <div class="account__block">
                                    <div class="account__block__img">
                                         <img src={GoogleImg} alt="Google" />
                                    </div>
                                    <div class="account__block__text">
                                        <p>Conta Google:</p>
                                        <p>
                                            {auth?.primaryEmail}&nbsp;&nbsp;
                                            <span><img src={SmallCheckImg} alt="Autenticado" /></span>&nbsp;&nbsp;
                                            <Tooltip id="google_account" message="Conta Google que foi conectada ao serviço">
                                                <img src={TipsImg} alt="Dicas" />
                                            </Tooltip>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {auth.googleAdsAccount?.id && (
                            <div class="cell small-12 large-6">
                                <div class="account__block">
                                    

                                    <div class={`${styles.billing_container} account__block__text`}>
                                        <div style={{ display:"flex" }}>
                                            <div class="account__block__img" >
                                                <img src={GoogleAdImg} alt="Google Ad Account" />
                                            </div>
                                            <div style={{ marginLeft:"10px" }}>
                                                <p>Conta Google Ads:</p>
                                                <p>
                                                    {auth.googleAdsAccount?.id}&nbsp;&nbsp;
                                                    <span><img src={SmallCheckImg} alt="Autenticado" /></span>
                                                    <Tooltip className="ml-0.5" id="mc_account" message="Conta que será utilizada para o gerenciamento das suas campanhas">
                                                        <img src={TipsImg} alt="Dicas" />
                                                    </Tooltip>
                                                </p>
                                            </div>
                                        </div>

                                        <a 
                                            className={ styles.config_payment_button } 
                                            href="https://ads.google.com/aw/billing/summary"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Configurar cobrança
                                            <GoLinkExternal 
                                                size={20} 
                                                style={{ marginLeft:"5px" }}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}

                        {auth.merchantAccount?.id && (
                            <div class="cell small-12">
                                <div class="account__block">
                                    <div class="account__block__img">
                                        <img src={MerchantImg} alt="Merchant center" />
                                    </div>
                                    <div class="account__block__text">
                                        <p>Conta Merchant Center:</p>
                                        <p>
                                            Id da Conta - {auth.merchantAccount?.id}&nbsp;&nbsp;
                                            <span><img src={SmallCheckImg} alt="Autenticado" /></span>&nbsp;&nbsp;
                                            <Tooltip className="ml-0.5" id="mc_account" message="Conta que será utilizada para enviar o feed de produtos">
                                                <img src={TipsImg} alt="Dicas" />
                                            </Tooltip>
                                        </p>
                                    </div>
                                </div>

                                {auth.store?.name && (
                                    <div class="account__block account__block--store">
                                        <div class="account__block__text">
                                            <p>Nome da sua loja</p>
                                            <p>{auth.store?.name}</p>
                                        </div>
                                    </div>
                                )}

                                {auth.store?.storeUrl && (
                                    <div className="account__block account__block--store">
                                        <div className="account__block__text">
                                            <p>
                                                URL da loja
                                                <Tooltip
                                                    className="ml-0.5"
                                                    id="store_url"
                                                    message="URL da sua loja virtual que  será vinculada à sua campanha"
                                                >
                                                    <img src={TipsImg} alt="Dicas" />
                                                </Tooltip>
                                            </p>
                                            <p>{auth.store?.storeUrl}</p>
                                        </div>
                                    </div>
                                )}

                                {auth.store?.address && (
                                    <div className="account__block account__block--store">
                                        <div className="account__block__text">
                                            <p>
                                                Endereço da loja
                                                <Tooltip
                                                    className="ml-0.5"
                                                    id="store_address"
                                                    message="Endereço físico da sua loja ou que está relacionado ao seu CNPJ"
                                                >
                                                    <img src={TipsImg} alt="Dicas" />
                                                </Tooltip>
                                            </p>
                                            <p>
                                                {address?.street} &nbsp;
                                                <UpdateAddress
                                                    address={address}
                                                    setAddress={setAddress}
                                                />
                                                <br />
                                                {address?.city} - {address?.state}
                                                <br />
                                                {address?.postalCode}
                                            </p>
                                        </div>
                                    </div>
                                )}

                                <div className="account__block account__block--store">
                                    <div className="account__block__text">
                                        <p>Telefone</p>
                                        <p>
                                            {formatPhoneNumber(auth?.store?.google?.phoneNumber)}{" "}
                                            {/* <Sidebar
                                            icon={<FiEdit color="#d1d3d5" size={16} />}
                                        >
                                            <p>Dica</p>
                                        </Sidebar> */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <DisconnectAccountsButtonWithResonForm forceRedirect={true} />
            </section>
        </>
    );
}

export default GoogleAccountsSection;
