import React, { useEffect } from "react";
import { api } from "../services/api";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";

export const NotificationContext = React.createContext({});

export function NotificationProvider({ children }) {
    const auth = useAuth();

    useEffect(() => {
        if(auth.store) {
            api.get("/notifications?read=false").then((response) => {
                const notifications = response.data;
    
                notifications.forEach(notification => {
                    toast[notification.type](notification.message);
                    api.put(`/notifications/${notification.id}`, { isRead: true });
                });
            })
        }
    }, [auth.store]);

    return (
        <NotificationContext.Provider value={null}>{children}</NotificationContext.Provider>
    );
}
