
import { AiFillWarning } from "react-icons/ai";
import { useEffect, useState } from "react";

import { GrClose } from "react-icons/gr";
import "./styles.scss";

const ReasonForm = ({ close, setModal, setOtherText, otherText, hasGoogleAdsCampaignId, setReasons, handleDisconnectAccount, loading }) => {
  const [dontUnderstand, setDontUnderstand] = useState(false);
  const [dontGotResult, setDontGotResult] = useState(false);

  const [hardToUse, setHardToUse] = useState(false);
  const [other, setOther] = useState(false);

  const [valid, setValid] = useState(false);

  useEffect(() => {
    validateInput();
    updateReasons();
  }, [dontUnderstand, dontGotResult, hardToUse, other, otherText])

  function validateInput() {
    const inputsCheckbox = [dontUnderstand, dontGotResult, hardToUse, other];
    const hasAnyCheckboxChecked = inputsCheckbox.find(data => data);

    if (!hasAnyCheckboxChecked) return setValid(false);
    if (other) return blockButtonIfOtherIsMarkAndHasNoText();

    setValid(true);
    return true;
  }

  function blockButtonIfOtherIsMarkAndHasNoText() {
    !otherText ? setValid(false) : setValid(true);
  }

  async function disconnect() {
    await handleDisconnectAccount("removed");
  }

  function updateReasons() {
    const inputsCheckbox = [dontUnderstand, dontGotResult, hardToUse, other];
    const texts = ["dont_understand_google_shopping", "no_results", "difficulties_using_application", "other"];

    const reasonsData = texts.filter((text, index) => inputsCheckbox[index]);
    setReasons(reasonsData);
  }

  function goToNextScreen() {
    setModal(1);
  }

  return (
    <div className="reason-form-container">

      <div className="header">
        <p>Por que você deseja desconectar suas contas?</p>
        <GrClose
          color="#6B7280"
          className="close-icon"
          onClick={close}
        />
      </div>

      <div className="form-container">

        <p>Por favor, indique os principais motivos que levaram a desconectar suas contas da integração Google com a plataforma Tray:</p>

        <div className="reason-form-input-container">
          <input
            checked={dontUnderstand}
            id="dont-understant-how-campaing-works"
            onChange={() => setDontUnderstand(!dontUnderstand)}
            type="checkbox"
          />
          <label htmlFor="dont-understant-how-campaing-works">
            Não entendo como funcionam as campanhas do Google Shopping
          </label>
        </div>

        <div className="reason-form-input-container">
          <input
            checked={dontGotResult}
            id="dont-got-results-expected"
            onChange={() => setDontGotResult(!dontGotResult)}
            type="checkbox"
          />
          <label htmlFor="dont-got-results-expected">
            Não obtive os resultados que esperava
          </label>
        </div>

        <div className="reason-form-input-container">
          <input
            checked={hardToUse}
            id="hard-to-use-the-tools"
            type="checkbox"
            onChange={() => setHardToUse(!hardToUse)}
          />
          <label htmlFor="hard-to-use-the-tools">
            Dificuldades de utilizar a ferramenta
          </label>
        </div>

        <div className="reason-form-input-container">
          <input
            checked={other}
            id="other"
            type="checkbox"
            onChange={() => setOther(!other)}
          />
          <label htmlFor="other">
            Outros:
          </label>
          <input
            className="other-input"
            disabled={!other}
            value={otherText}
            placeholder="Insira o motivo"
            style={other ? { background: "white" } : {}}
            onChange={(e) => setOtherText(e.target.value)}
            type="text"
          />
        </div>

        <div className="warn-container">
          <AiFillWarning
            color="#F9CB7C"
            size={18}
          />
          <div className="content-container">
            <strong>Alerta de Otimização do Google Ads!</strong>
            <p>
              Seu anúncio no Google necessita de mais dados, como impressões e cliques, por um período mínimo de 14 dias para
              uma otimização eficaz. Se os resultados ainda não aparecerem, <a target="blank" href="https://www.tray.com.br/vender-pelo-google/">clique aqui e confira as dicas de otimização para Integração Google Ads.</a>
            </p>
          </div>
        </div>

      </div>

      <div className="buttons-container">
        <button className="close-action-button" onClick={close}>
          Fechar
        </button>

        {hasGoogleAdsCampaignId ? (
          <button
            className="action-button"
            disabled={!valid}
            onClick={goToNextScreen}
            style={!valid ? { filter: "opacity(50%)" } : {}}
          >
            Confirmar
          </button>
        ) : (
          <button
            className="action-button-red"
            onClick={disconnect}
          >
            {loading ? (
              <>
                <div class="custom-loader"></div> Desconectando
              </>
            ) : (
              <>Desconectar contas</>
            )}
          </button>
        )}
      </div>

    </div>
  );
}

export default ReasonForm;