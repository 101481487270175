import cx from "classnames";

import styles from "./styles.module.scss";

import SpinnerSvgGif from "../../assets/img/icons/spinner.svg";

export function Button({
  children,
  submitingMessage = "",
  disabled = false,
  isSubmitting = false,
  ...rest
}) {
  return (
    <button
      className={cx(styles.btn, {
        "with-icon": isSubmitting,
        [`${styles.withIconDirection}`]: isSubmitting,
      })}
      disabled={isSubmitting || disabled}
      {...rest}
    >
      {isSubmitting && (
        <img
          className={styles.icon}
          src={SpinnerSvgGif}
          alt="Animation spinner"
        />
      )}
      {isSubmitting ? submitingMessage : children}
    </button>
  );
}
