import { useEffect, useState } from "react";
import ApexChart from "react-apexcharts";
import { api } from "../../services/api";
import { formatConversions, formatMoney } from "../../utils/format";
import SpinnerBlueSvgGif from "../../assets/img/icons/spinner-blue.svg";

export function Chart({ lastDays }) {
  const [chart, setChart] = useState({
    series: [],
    colors: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api
      .get("/google/reports/chart", {
        params: {
          dateRange: lastDays,
        },
      })
      .then((result) => {
        const clicks = {
          name: "Cliques",
          data: result.data.map((item) => {
            return {
              x: `${item.date.day}/${item.date.month}`,
              y: item?.clicks || 0
            }
          }),
          color: "#1abc9c",
        };

        let conversions = null;
        if (result.data && result.data.some(d => d.conversions)) {
          conversions = {
            name: "Conversões",
            data: result.data.map((item) => {
              return {
                x: `${item.date.day}/${item.date.month}`,
                y: formatConversions(item?.conversions || 0, true)
              }
            }),
            color: "#f1556c"
          };
        }

        let cost = null;
        if (result.data && result.data.some(d => d.cost)) {
          cost = {
            name: "Custo",
            data: result.data.map((item) => {
              return {
                x: `${item.date.day}/${item.date.month}`,
                y: item?.cost || 0
              }
            }),
            color: "#008ffb"
          };
        }

        let conversionsValue = null;
        if (result.data && result.data.some(d => d.conversionsValue)) {
          conversionsValue = {
            name: "Total vendido (R$)",
            data: result.data.map((item) => {
              return {
                x: `${item.date.day}/${item.date.month}`,
                y: item?.conversionsValue || 0
              }
            }),
            color: "#f7b84b",
          };
        }

        let series = [clicks, cost, conversions, conversionsValue];

        // Remove null objects from series array
        series = series.filter(Boolean);

        setChart({
          series,
          colors: series.map((serie) => serie.color),
        });
      })
      .catch((err) => { })
      .finally(() => setLoading(false));
  }, [lastDays]);

  const options = {
    noData: {
      text: 'Ainda não existem dados a serem exibidos.',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '14px'
      }
    },
    chart: {
      height: 350,
      toolbar: {
        show: false
      },
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
      colors: chart.colors,
    },
    title: {
      enabled: false,
    },
    grid: {
      row: {
        colors: ["transparent"],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val, opt) {
          const seriesIndex = opt?.seriesIndex;
          const seriesName = opt?.w?.config?.series[seriesIndex]?.name;
          if (seriesName === 'Custo' || seriesName === 'Total vendido (R$)') {
            return formatMoney(Number(val));
          }

          return val;
        },
      },
    },
  };

  return (
    loading ? (
      <div 
        className="cell small-12 large-8 chart-dashboard-free p-0" 
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
      </div>
    ) : (
      <ApexChart
        height={350}
        options={options}
        series={chart.series}
        type="line"
        className="cell small-12 large-8 chart-dashboard-free p-0"
      />
    )
  );
}