import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Modal } from "../Modal";
import { BsXLg } from "react-icons/bs";
import { api } from "../../services/api";
import { toast } from "react-toastify";
import SpinnerBlueSvgGif from "../../assets/img/icons/spinner-blue.svg";

export default function ProductJsonModal({ handleClose, isOpen, productId, reportType, freeListingsImage }) {
    const auth = useAuth();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setProduct(null);
        getProduct();
    }, [isOpen]);

    const getProduct = async () => {
        try {
            setLoading(true);

            // Call the API url to get the product data
            const response = await api.get(`/google/merchant_center/accounts/${auth.merchantAccount?.id}/products/${productId}`);
            const data = response.data;

            if(reportType === "free_listings") data.imageLink = freeListingsImage;
            setProduct(data)
            
        } catch (e) {
            console.log(e)
            toast.error(`Não foi possível carregar os dados do produto. Por favor, tente novamente mais tarde.`);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal closeModal={handleClose} isOpen={isOpen} maxWidth={1000}>
            <section class="logs__modal">
                <main class="grid-x">
                    <section class="cell small-12 logs__modal__header">
                        <h1>Dados do produto no Google Merchant Center</h1>
                        <button onClick={handleClose}><i><BsXLg /></i></button>
                    </section>

                    <section class="cell small-12 logs__modal__body">
                        {loading ? (
                            <div className="text-align-center" style={{ display: 'block' }}>
                                <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
                            </div>
                        ) : (
                            !loading && !product ? (
                                <div className="text-align-center" style={{ display: 'block' }}>
                                    <p>Sem dados para exibir.</p>
                                </div>
                            ) : (
                                <div style={{
                                    color: "#6c757d",
                                    fontSize: ".875rem",
                                    lineHeight: "1.1875rem",
                                    verticalAlign: "text-top",
                                    backgroundColor: "#f4f5f7",
                                    padding: "0.875rem"
                                }}>
                                    <pre>{JSON.stringify(product, {}, 2)}</pre>
                                </div>
                            )
                        )}
                    </section>
                </main>
            </section>
        </Modal>
    );
}
