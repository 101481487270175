import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

// import { useAuth } from "../../../hooks/useAuth";
import { useWizard } from "../../../hooks/useWizard";
import { wizardSteps } from "../../../contexts/WizardContext";

import GoogleLogo from "../../../assets/img/icons/google.svg";
import IconeIntrod from "../../../assets/img/icons/ico-intro.svg";

import "../../../styles/style_wizard.css";

import { handleScroll } from "../../../utils/script_wizard";

// Swiper styles must use direct files imports
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module

function Start() {
  const wizard = useWizard();

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Google Shopping</title>
        <meta
          name="description"
          content="Amplie a visibilidade de seus produtos e encontre o cliente certo, dentre os 1 bilhão de usuários por mês dos produtos Google."
        />
        <meta property="og:title" content="Início  - Traygle" />
        <meta
          property="og:description"
          content="Amplie a visibilidade de seus produtos e encontre o cliente certo, dentre os 1 bilhão de usuários por mês dos produtos Google."
        />
      </Helmet>
      <div className="wizard-container">
        <div id="main">
          <div className="header-floating bg_color_white">
            <div className="container flex justify-between">
              <div>
                <strong className="color_font_dark">
                  Integração Google Ads
                </strong>
                <span className="color_font_medium">
                  Conecte-se a milhões de consumidores em busca de produtos como
                  o seu
                </span>
              </div>
              <div
                className="bt__action button small bg_color_green color_font_white"
                onClick={() => {
                  const section = document.getElementById("section__start");
                  if (section) {
                    section.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                Comece já
              </div>
            </div>
          </div>

          <section className="section__intro p-t-100 bg_color_gray">
            <div className="container flex align-center">
              <div className="middle-left box-text w-52">
                <img src={GoogleLogo} alt="Google" />

                <strong className="color_font_dark">
                  Gere mais tráfego e vendas com a Integração Google Ads
                </strong>
                <p className="description">
                  Conecte-se a milhões de consumidores em busca de produtos como
                  o seu.
                </p>
                <div
                  className="bt__action  button bg_color_green color_font_white"
                  onClick={() => {
                    const section = document.getElementById("section__start");
                    if (section) {
                      section.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  Comece já
                </div>
              </div>
              <div className="middle-right box-image w-48">
                <img src={IconeIntrod} alt="Ícone de introdução" />
              </div>
            </div>
          </section>

          <section className="section__go_down bg_color_gray">
            <span
              className="pointer flex justify-center align-center bg_color_blue_medium_two"
              onClick={() => {
                const section = document.getElementById("section_values");
                if (section) {
                  section.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="34"
                fill="none"
              >
                <path
                  fill="#ffffff"
                  fill-rule="evenodd"
                  d="M10.083 20.898v-12.5c0-.309.41-.56.917-.56.506 0 .917.251.917.56v12.5l3.935-3.65a.968.968 0 0 1 1.296 0 .807.807 0 0 1 0 1.203l-5.5 5.1a.968.968 0 0 1-1.296 0l-5.5-5.1a.807.807 0 0 1 0-1.202.968.968 0 0 1 1.296 0l3.935 3.649Z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </section>

          <section
            id="section_values"
            className="section_values bg_color_gray flex justify-center align-center"
          >
            <div className="bg_color_blue_medium">
              <strong className="color_font_blue">
                Gaste R$ 1.200. Ganhe R$ 1.200.{" "}
                <a
                  href="https://www.google.com/intl/pt-br_br/ads/coupons/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Saiba mais
                </a>
              </strong>
            </div>
          </section>

          <section className="video-container">
            <div class="container">
              <div class="center">
                <span class="subtitle">Divulgue seus produtos para milhões de compradores</span>
                <iframe src="https://www.youtube.com/embed/94akvAVb7Gs?si=FSVFI6GZrk0V3BfE" width="100%" height="100%" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </div>
          </section>  

          <section className="section__items m-t-75 m-b-100">
            <div className="container bg_color_blue_light p-t-100 p-b-50">
              <div className="center">
                <span className="subtitle">
                  Alcance as pessoas onde elas compram com o Google
                </span>
              </div>
              <ul className="flex justify-around p-t-50">
                <li className="center">
                  <strong className="flex align-center justify-center bg_color_blue_medium_two color_font_white">
                    <div
                      className="counter-number"
                      data-from-value="0"
                      data-to-value="80"
                      data-duration="12"
                    >
                      0
                    </div>
                    %
                  </strong>
                  <span className="color_font_dark">
                    dos consumidores pesquisam ou navegam on-line antes de
                    realizar uma compra¹
                  </span>
                </li>
                <li className="center">
                  <strong className="flex align-center justify-center bg_color_blue_medium_two color_font_white">
                    <div
                      className="counter-number"
                      data-from-value="0"
                      data-to-value="41"
                      data-duration="24"
                    >
                      0
                    </div>
                    %
                  </strong>
                  <span className="color_font_dark">
                    dos consumidores recorrem ao Google para descobrir novas
                    marcas²
                  </span>
                </li>
              </ul>
              <p className="center">
                <span>
                  1. Google/Ipsos, estudo sobre compras de fim de ano, 13 de
                  outubro a 23 de novembro de 2022.
                </span>
                <span>
                  2. Google/Ipsos, sobre a COVID-19, 20 a 23 de maio de 2021.
                </span>
              </p>
            </div>
          </section>

          <section className="section__go_start p-t-100 p-b-100">
            <div className="container">
              <div className="center">
                <span className="subtitle">
                  Impulsione seu negócio com uma campanha Performance Max
                </span>
                <p className="description">
                  Com a Tray e o Google, é simples configurar sua campanha.
                  Depois de criar ou conectar suas{" "}
                  <a
                    href="https://support.google.com/google-ads/answer/9455541?hl=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    contas no Merchant Center e no Google Ads
                  </a>
                  , você já pode criar uma campanha. O que você pode esperar:
                </p>
              </div>
              <div className="flex">
                <div className="w-52">
                  <div className="block-image"></div>
                </div>
                <div className="blocks-values flex flex-column justify-center w-48">
                  <div className="block block-first">
                    <strong>
                      Gere vendas com uma campanha Performance Max
                    </strong>
                    <span>
                      O Google conecta você automaticamente a clientes em canais
                      como YouTube, Pesquisa, Discover, Gmail, Maps e em toda a
                      internet. Tudo a partir de uma única campanha.
                    </span>
                  </div>
                  <div className="block block-second">
                    <strong>Defina o orçamento certo para você</strong>
                    <span>
                      Você vai receber um orçamento sugerido, que pode ser
                      ajustado a qualquer momento. A melhor parte? Você só paga
                      quando alguém clicar no seu anúncio.
                    </span>
                  </div>
                  <div className="block block-third">
                    <strong>Otimize a qualquer momento</strong>
                    <span>
                      Você pode acessar métricas importantes, como cliques e
                      conversões, para monitorar e melhorar sua performance.
                      Além disso, você pode otimizar as campanhas para momentos
                      específicos ajustando seu orçamento.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            id="section__start"
            className="section__start p-t-100 p-b-100"
          >
            <div className="container bg_color_blue_dark color_font_white">
              <div className="flex justify-center center">
                <div className="values p-60 w-50">
                  <div className="flex align-center justify-center">
                    <div className="flex align-center">
                      <strong>Receba</strong>
                      <b>R$</b>
                      <strong>1.200</strong>
                    </div>
                  </div>
                  <span>
                    em créditos para anúncios ao investir seus primeiros R$1.200
                    no Google Ads dentro de 60 dias.*
                  </span>
                </div>
                <div className="go-to p-60 w-50">
                  <span>
                    Oferta disponível apenas para novos anunciantes com endereço
                    de cobrança no Brasil. Veja os termos e condições dessa
                    oferta.
                  </span>
                  <button
                    className="button bg_color_green color_font_white"
                    onClick={() => {
                      wizard.updateCurrentStep(
                        wizardSteps.GOOGLE_ACCOUNT_CONNECTION
                      );
                    }}
                  >
                    Comece já
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section className="section__termos bg_color_gray p-t-100 p-b-100">
            <div className="container">
              <strong>Termos e Condições desta oferta</strong>
              <ul className="color_font_dark_second">
                <li>1. Oferta disponível enquanto durarem os estoques.</li>
                <li>
                  2. Disponível apenas para anunciantes novos no Google Ads e
                  com endereço de faturamento no Brazil. Uma oferta promocional
                  por anunciante.
                </li>
                <li>
                  3. Se quiser ativar a oferta, clique no botão ou link
                  associado a ela para que seja usada automaticamente na sua
                  primeira conta do Google Ads. É necessário implementar a
                  oferta até 14 dias após a primeira impressão de anúncio dessa
                  conta do Google Ads.
                </li>
                <li>
                  4. A oferta promocional só será adicionada à sua conta se um
                  perfil para pagamentos e uma forma de pagamento válida forem
                  incluídos durante a inscrição.
                </li>
                <li>
                  5. Para receber o crédito do Google Ads: depois de implementar
                  a oferta promocional na conta do Google Ads, suas campanhas
                  publicitárias precisam acumular pelo menos R$1200,00 (não
                  incluindo tributos ou taxas relevantes) em 60 dias. Fazer um
                  pagamento de R$1200,00 não é suficiente. Os custos de
                  publicidade começam a ser somados para chegar ao valor de
                  R$1200,00 depois que a oferta promocional for implementada.
                </li>
                <li>
                  6. Depois que a etapa 5 for concluída, considerando que todas
                  as verificações de qualificação sejam cumpridas, o crédito
                  normalmente será adicionado em até 35 dias ao resumo de
                  faturamento da sua conta do Google Ads.
                </li>
                <li>
                  7. O crédito expira 60 dias depois de ser adicionado à sua
                  conta.
                </li>
                <li>
                  8. O crédito é válido apenas a custos de publicidade futuros.
                  Ele não pode ser usado para cobrir gastos acumulados antes de
                  você implementar a oferta promocional na conta do Google Ads.
                </li>
                <li>
                  9. Você não receberá nenhuma notificação quando o crédito da
                  sua conta do Google Ads esgotar. Se houver gastos extras, eles
                  serão cobrados usando sua forma de pagamento. Se não quiser
                  continuar anunciando, pause ou exclua as campanhas a qualquer
                  momento.
                </li>
                <li>
                  10. Sua conta precisa ser faturada pelo Google Ads e
                  permanecer em situação regular para que o crédito seja válido.
                </li>
                <li>
                  11. O Google Ads se reserva o direito de remover a oferta
                  promocional da sua conta se houver falha no pagamento, uma
                  oferta ser adicionada por engano, você violar estes termos ou
                  sua conta não permanecer em situação regular. Nesses casos, se
                  o crédito promocional já foi concedido, o Google Ads se
                  reserva o direito de anular esse crédito.
                </li>
                <li>
                  12. Confira todos os Termos e Condições aqui:{" "}
                  <a
                    href="https://www.google.com/intl/pt-br_br/ads/coupons/terms/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.google.com/intl/pt-br_br/ads/coupons/terms/
                  </a>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Start;
