import { useEffect, useState } from "react";
import cx from "classnames";
import { Portal } from "react-portal";
import { slide as Menu } from "react-burger-menu";

import styles from "./styles.module.scss";

export function Sidebar({
  icon = undefined,
  children,
  isSidebarOpen = false,
  onClose = undefined,
  onOpen = undefined,
  className = "",
  sidebarClass = "",
}) {
  const [isOpen, setIsOpen] = useState(isSidebarOpen);

  function handleOpenSidebar() {
    if (typeof onOpen === "function") {
      onOpen();
    } else {
      setIsOpen(true);
    }
  }

  async function handleCloseSidebar(fn = undefined) {
    try {
      if (typeof fn === "function") {
        await fn();
      }

      if (typeof onOpen === "function") {
        onClose();
      } else {
        setIsOpen(false);
      }
    } catch {}
  }

  useEffect(() => {
    setIsOpen(isSidebarOpen);
  }, [isSidebarOpen]);

  return (
    <>
      {typeof icon !== "undefined" && (
        <span className={styles.sideBarIcon} onClick={handleOpenSidebar}>
          {icon}
        </span>
      )}
      <Portal node={document && document.getElementById("sidebarsContainer")}>
        <div
          className={cx("", {
            [`${styles.sidebarContainer}`]: className.length === 0,
            [`${className}`]: className.length > 0,
          })}
        >
          <Menu
            className={cx("", {
              [`${styles.sidebar}`]: sidebarClass.length === 0,
              [`${sidebarClass}`]: sidebarClass.length > 0,
            })}
            isOpen={isOpen}
            onOpen={handleOpenSidebar}
            onClose={handleCloseSidebar}
            right
          >
            <div className={styles.sidebarItem}>
              {typeof children === "function"
                ? children({
                    handleCloseSidebar,
                  })
                : children}
            </div>
          </Menu>
        </div>
      </Portal>
    </>
  );
}
