import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { toast } from "react-toastify";

import { ProgressIndicator } from "../../../components/Wizard/ProgressIndicator";
import { NoAccount } from "../../../components/GoogleAdsAccountComponents/NoAccount";
import { HasAccounts } from "../../../components/GoogleAdsAccountComponents/HasAccounts";
import { OnlyOneAccount } from "../../../components/GoogleAdsAccountComponents/OnlyOneAccount";
import { useAuth } from "../../../hooks/useAuth";
import { api } from "../../../services/api";

import SpinnerBlueSvgGif from "../../../assets/img/icons/spinner-blue.svg";

export default function GoogleAdsAccountPage() {
  const auth = useAuth();
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.post(`google/merchant_center/accounts/${auth.merchantAccount?.id}/start_product_sync`).then(() => toast.success(`Seus produtos estão sendo sincronizados.`));

    async function loadAccounts() {
      try {
        const response = await api.get("google/ads/customers");
        setLoading(false);
        setAccounts(response.data);
      } catch {}
    }
    loadAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Google Ads - Traygle</title>
        <meta name="description" content="A sua conta Merchant Center foi configurada com sucesso. Agora vamos conectar a sua conta Google Ads." />
        <meta property="og:title" content="Google Ads - Traygle" />
        <meta property="og:description" content="A sua conta Merchant Center foi configurada com sucesso. Agora vamos conectar a sua conta Google Ads." />
      </Helmet>

      <div className="create-google-ads">
        <ProgressIndicator currentStep={3} />

        {loading ? (
          <div className="widget-products">
            <h1 className="widget-products__title"> Conectar uma conta Google Ads</h1>
            <p className="widget-products__text">
              Agora vamos conectar a sua conta Google Ads. <br />
              Ela permite que você crie {auth.campaignTypeNamePlural} no Google.
            </p>

            <div
              className="widget__connect widget__connect--success"
              style={{
                justifyContent: "center",
              }}
            >
              <div className="widget__connect__img">
                <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
              </div>
            </div>
          </div>
        ) : accounts.length === 0 ? (
          <NoAccount />
        ) : (
          <HasAccounts accounts={accounts} />
        )}
      </div>
    </>
  );
}
