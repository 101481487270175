import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Modal } from "../Modal";
import { HiOutlineArrowTopRightOnSquare } from "react-icons/hi2";
import { Button } from "../Button";
import { formatShortDate } from "../../utils/format";
import { api } from "../../services/api";
import { toast } from "react-toastify";

export default function ReviewRequestModal({ handleClose, isOpen }) {
    const knowMoreUrl = 'https://support.google.com/merchants/answer/6109185?hl=pt-BR';
    const sevenDaysFromNow = (new Date()).setDate((new Date()).getDate() + 7);

    const auth = useAuth();

    const [agreeCheckbox, setAgreeCheckbox] = useState(false);
    const [loading, setLoading] = useState(false);

    const requestReview = async () => {
        setLoading(true);

        try {
            await api.post(`/google/merchant_center/accounts/${auth.merchantAccount?.id}/request_review`);
            await auth.loadUser();
            toast.success(`A solicitação para a revisão manual foi enviada para as equipes do Google. O prazo mínimo é de 48 horas.`);
        } catch (err) {
            if (err?.response?.data?.error?.code === "4221") {
                toast.error(`O pedido de revisão não pode ser realizado porque não existe nenhum produto cadastrado no Merchant Center. Aguarde o término da sincronização dos produtos para solicitar a revisão novamente`);
            } else if (err?.response?.data?.error?.code === "4222") {
                toast.error('A sua conta do Merchant Center já está em revisão. Por favor, aguarde a conclusão desta etapa.');
            } else if (err?.response?.data?.error?.code === "4223") {
                toast.error('Por favor, corrija os erros da sua conta antes de solicitar a revisão.');
            }
            else {
                toast.error('Não foi possível solicitar a revisão manual da sua conta. Aguarde alguns dias para tentar novamente.')
            }
        } finally {
            handleClose();
            setLoading(false);
        }
    }

    return (
        <Modal closeModal={handleClose} isOpen={isOpen} maxWidth={650}>
            <section class="review-request__modal">
                <main class="grid-x">
                    <section class="cell small-12 review-request__modal__header">
                        <h1>Antes de solicitar revisão</h1>
                        <i class="fas fa-edit"></i>
                    </section>

                    <section class="cell small-12 review-request__modal__body">
                        <p>
                            Recomendamos que todos os problemas de produto e conta apontados sejam
                            corrigidos antes de solicitar a revisão manual. Isto evitará novas reprovações
                            e problemas na sua integração.
                        </p>

                        <div class="checkbox-group">
                            <label class="control control--checkbox">
                                <input
                                    type="checkbox"
                                    id="review-check"
                                    name="review-check"
                                    className="checkbox-group__input"
                                    checked={agreeCheckbox}
                                    onChange={() => {
                                        setAgreeCheckbox((oldVersion) => !oldVersion);
                                    }}
                                />
                                <div class="control__indicator"></div>
                                <label for="review-check">Estou ciente das recomendações e desejo seguir com a solicitação</label>
                            </label>
                        </div>

                        <div class="review-warning">
                            <p>
                                <span>Atenção</span><br />
                                Somente será possível solicitar a revisão a cada <span>7 dias.</span><br />
                                Solicitando agora, a previsão para a próxima será dia <span>{formatShortDate(sevenDaysFromNow)}.</span>{" "}
                                <a href={knowMoreUrl} target="_blank">Saiba mais</a>
                                <i><HiOutlineArrowTopRightOnSquare /></i>
                            </p>
                        </div>
                    </section>

                    <section class="cell small-12 review-request__modal__footer">
                        <button onClick={() => handleClose()}>Fechar</button>
                        <Button
                            disabled={!agreeCheckbox}
                            onClick={() => requestReview()}
                            isSubmitting={loading}
                            submitingMessage={'Solicitando a revisão...'}
                        >
                            Quero solicitar a revisão manual
                        </Button>
                    </section>
                </main>
            </section>
        </Modal>
    );
}
