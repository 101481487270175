export function ProgressIndicator({ currentStep }) {
    const getClass = (step) => {
        if (currentStep === step) return 'is-current';
        if (currentStep > step) return 'is-complete';
        return '';
    }

    return (
        <ol className="progress-indicator">
            <li className={getClass(1)} data-step="1">
                <span>Autenticação<br/>Google</span>
            </li>
            <li className={getClass(2)} data-step="2">
                <span>
                    Google
                    <br />Merchant Center
                </span>
            </li>
            <li className={getClass(3)} data-step="3">
                <span>
                    Google
                    <br /> Ads
                </span>
            </li>
            <li className={getClass(4)} data-step="4">
                <span>
                    Criar campanha
                </span>
            </li>
        </ol>
    );
}
