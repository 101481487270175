import GoogleImg from "../../assets/img/content/google.png";
import "./styles.scss";

function GoogleSignInButton({ onClick }) {
    return (
        <div className="google-btn" id="create-google-account-button"  onClick={onClick}>
            <div className="google-icon-wrapper">
                <img className="google-icon" src={GoogleImg} alt="Google" />
            </div>
            <p className="btn-text"><b>Faça login no Google</b></p>
        </div>
    );
}

export default GoogleSignInButton;