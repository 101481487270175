import { BsExclamationCircle } from "react-icons/bs";
import styles from "./styles.module.scss";
import cx from "classnames";

function HelpLink({ isWizard }) {
    return (
        <a
            target="_blank"
            href="https://www.tray.com.br/central-google-shopping/#faq"
            style={{ position: 'relative' }}
            className={cx("dashboard__help", { [`${styles.helpLinkWizard}`]: isWizard, "widget": isWizard })}
        >
            <BsExclamationCircle size={14} color="#6C757D" />{" "}
            <span className="ml-0.25">Preciso de ajuda</span>
        </a>
    );
}

export default HelpLink;