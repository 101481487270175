/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Tooltip } from "../../components/Tooltip";
import TipsImg from "../../assets/img/icons/tips.svg";
import CurrencyInput from "react-currency-input-field";
import { formatMoney } from "../../utils/format";
import { FaDollarSign } from "react-icons/fa";

function InvestmentSection({ campaign, changeCampaignData }) {
    const [amount, setAmount] = useState(90);

    useEffect(async () => {
        setAmount(Number(campaign.dailyAmountInCents) / 100);
    }, [campaign])

    return (
        <section class="cell small-12 edit-campaign__investment--section">
            <i><FaDollarSign /></i>
            <h3>Investimento e saldo</h3>
            <h4>Defina o valor diário para investir em sua campanha</h4>

            <div class="investment-picker">
                {amount < 35 ? <h4>Orçamento mínimo recomendado é de R$ 35,00</h4> : <h4>&nbsp;</h4>}
                <input
                    className={`slider ${amount < 35 ? 'low-level' : ''}`}
                    id="range"
                    type="range"
                    value={amount}
                    onChange={(event) => changeCampaignData({ dailyAmountInCents: Number(event.target.value) * 100 })}
                    min="1"
                    max="900"
                    step="1"
                />
                <div class="investment-value">
                    <div>
                        <span class="currency-sign">R$</span>
                        <CurrencyInput
                            id="campaign__amountInvested--daily"
                            name="campaign__amountInvested--daily"
                            placeholder="Digite um valor"
                            value={amount}
                            decimalsLimit={2}
                            onValueChange={(value) => {
                                const currency = value ? Number(value.replace(",", ".")) : 0;
                                changeCampaignData({
                                    dailyAmountInCents: currency * 100,
                                });
                            }}
                            decimalScale={2}
                            step={1}
                            maxLength={900}
                            prefix=" "
                            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                        />
                        <span>média&nbsp;diária</span>
                        <Tooltip
                            id="campaign__invest__tooltip"
                            message="O algoritmo poderá investir mais em um dia quando identificar oportunidades de venda. O valor nunca será maior que o ‘orçamento mensal máximo’ ao lado."
                        >
                            <img src={TipsImg} alt="Dicas" height={42} width={42} />
                        </Tooltip>
                    </div>
                    <div>
                        Orçamento mensal máximo: {formatMoney(amount * 30)}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InvestmentSection;
