import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import Helmet from "react-helmet";
import { RiCursorLine } from "react-icons/ri";

import { useAuth } from "../../hooks/useAuth";
import { useWizard } from "../../hooks/useWizard";
import { wizardSteps } from "../../contexts/WizardContext"

import { Chart } from "../../components/Chart";

import { api } from "../../services/api";
import Navigation from "../../components/Dashboard/Navigation";
import ProductsStats from "../../components/Dashboard/ProductsStats";
import HelpLink from "../../components/HelpLink";
import PmaxAd from "../../components/Dashboard/Free/PmaxAd";
import CampaignCarousel from "../../components/CampaignCarousel";

import CampaingCreationCallToAction from "../../components/CampaingCreationCallToAction"
import { getCampaign } from "../../utils/getCampaign";

function FreeDash() {
  const auth = useAuth();
  const history = useHistory();
  
  const wizard = useWizard();
  const [ hasCampaign, setCampaignId ] = useState();

  useEffect(() => {
    (async () => {
      const campaignId = await getCampaign(auth.store.id)
      setCampaignId(campaignId);
    })()
  }, []);

  const [issues, setIssues] = useState({
    accountLevelIssues: [],
    productLevelIssues: [],
  });
  const [generalStats, setGeneralStats] = useState({
    clicks: 0,
    cost: "0",
    conversions: 0,
    conversionsValue: "0",
    roas: 0,
  });
  const [chart, setChart] = useState({
    series: [],
    colors: [],
  });
  const [lastDays, setLastDays] = useState("LAST_7_DAYS");

  useEffect(() => {
    api
      .get("/google/reports/issues")
      .then((result) => {
        setIssues(result.data);
      })
      .catch((err) => { });

    api
      .get("/google/reports/general_stats", {
        params: {
          dateRange: lastDays,
        },
      })
      .then((result) => {
        setGeneralStats(result.data);
      })
      .catch((err) => { });

    api
      .get("/google/reports/chart", {
        params: {
          dateRange: lastDays,
        },
      })
      .then((result) => {
        const clicks = {
          name: "Cliques",
          data: result.data.map((item) => {
            return {
              x: `${item.date.day}/${item.date.month}`,
              y: item.clicks
            }
          }),
          color: "#1abc9c",
        };

        let series = [clicks];

        // Remove null objects from series array
        series = series.filter(Boolean);

        setChart({
          series,
          colors: series.map((serie) => serie.color),
        });
      })
      .catch((err) => { });
  }, [lastDays]);

  async function handleRestartCampaignCreation() {
    try {
      wizard.updateCurrentStep(wizardSteps.SSC_CREATION);
      history.push("/wizard");
    } catch { }
  }

  return (
    <>
      <Helmet>
        <title>Dashboard - Traygle</title>
        <meta name="description" content="Dashboard Traygle" />
        <meta property="og:title" content="Dashboard - Traygle" />
        <meta property="og:description" content="Dashboard Traygle" />
      </Helmet>

      <main role="main" style={ hasCampaign ? {} : { overflow:"initial",position:"relative" } }>
        <section className="grid-container">
          <div className="dashboard dashboard__free" style={ hasCampaign ? {} : {minHeight:"1px"}}>

            <Navigation currentSection="main" />

            <section className="dashboard__visao--geral">
              <section className="dashboard__visao--geral__header">
                
                {hasCampaign
                 ? (<h1>Visão Geral</h1>)
                 : (<h1 className="dashboard__visao--geral__header__title--alternative">Visão Performance Max</h1>)
                }
                
                {hasCampaign &&
                  <aside className="dashboard__filters_buttons">
                    <button
                      className={cx("", {
                        active: lastDays === "LAST_7_DAYS",
                      })}
                      onClick={() => setLastDays("LAST_7_DAYS")}
                    >
                      7 Dias
                    </button>
                    <button
                      className={cx("", {
                        active: lastDays === "LAST_30_DAYS",
                      })}
                      onClick={() => setLastDays("LAST_30_DAYS")}
                    >
                      30 Dias
                    </button>
                  </aside>
                }
              </section>
              
              { hasCampaign &&
                <main className="grid-x dashboard__infos gap-1.5" >
                  <section className="cell small-12 large-4 dashboard__infos--free-header-item-1">
                    <section className="dashboard__v-geral__item clicks">
                      <aside className="fs-0">
                        <RiCursorLine size={22} />
                      </aside>

                      <main>
                        <h4>{generalStats?.clicks}</h4>
                        <p>
                          Cliques
                        </p>
                      </main>
                    </section>

                    <div className="dashboard__infos--subscribe">
                      <h4>Quer ver mais Indicadores?</h4>

                      <a onClick={handleRestartCampaignCreation}>
                        Crie uma {auth.campaignTypeName}
                      </a>
                    </div>
                  </section>

                  <Chart lastDays={lastDays} />
                </main>
              }
            </section>

            {hasCampaign && 
              <section className="dashboard__products">
                <section className="dashboard__products--header">
                  <h2>Produtos</h2>
                </section>

                <ProductsStats />
              </section>
            }

            {/* <PmaxAd /> */}

            {hasCampaign && <HelpLink />}
          </div>
        </section>
        {!hasCampaign && 
          <>
            <div>
              <main 
                className="grid-x dashboard__infos gap-1.5" 
                style={{ position:"absolute", zIndex:1, maxWidth:"1200px", left:"50%", transform:"translate(-50%)" }}
              >
                <section className="cell small-12 large-4 dashboard__infos--free-header-item-1">
                  <section className="dashboard__v-geral__item clicks">
                    <aside className="fs-0">
                      <RiCursorLine size={22} />
                    </aside>

                    <main>
                      <h4>{generalStats?.clicks}</h4>
                      <p>
                        Cliques
                      </p>
                    </main>
                  </section>

                  <div className="dashboard__infos--subscribe">
                    <h4>Quer ver mais Indicadores?</h4>

                    <a onClick={handleRestartCampaignCreation}>
                      Crie uma {auth.campaignTypeName}
                    </a>
                  </div>
                </section>

                <Chart lastDays={lastDays} />
              </main>

              <CampaingCreationCallToAction/>
            </div>
            <CampaignCarousel/>
          </>
        }
      </main>
    </>
  );
}

export default FreeDash;
