import { api } from "../services/api";

export async function GoToCreateCampaignPage(next, auth) {
  // Get invitation link created when the user create the google ads account
  const originalInventationLink = await getInventationLink(auth.store.id);
  const googleAdsCustomerId = await getCustomerId(auth.store.id);

  let validInvitationLink = originalInventationLink || "";
  const invitationData = await getInvitationStatus(auth, googleAdsCustomerId);

  const ocid = getOcid(originalInventationLink);
  if (!invitationData) return openSummaryAndMoveToPmax(next);

  // Get last invitation link
  const ivid = invitationData.resourceName.split("customerUserAccessInvitations/")[1];
  const lastInvitationLink = `https://ads.google.com/nav/startacceptinvite?ivid=${ivid}&ocid=${ocid}`;

  const newInvitationLink = await sendNewInvitationLink(auth, invitationData.emailAddress, ocid);

  //Inivitation was already accepted
  if (newInvitationLink.error) return openSummaryAndMoveToPmax(next);

  //Invitation is expired or declined
  if (invitationData.invitationStatus === "EXPIRED" || invitationData.invitationStatus === "DECLINED") {
    //Set validInvitationLink as the new invitation link or the last invitation link in case of the last invitation link still be valid
    validInvitationLink = newInvitationLink.data || lastInvitationLink;
  } else validInvitationLink = lastInvitationLink;

  openAndListenToInvitationLink(validInvitationLink, auth, next, googleAdsCustomerId);
}

async function getInventationLink(storeId) {
  try {
    const stores = await api.get(`platforms/tray/stores/${storeId}`);
    return stores.data?.google?.googleAdsInvitationLink;
  } catch (e) {
    return "";
  }
}

async function getCustomerId(storeId) {
  try {
    const res = await api.get(`platforms/tray/stores/${storeId}`);
    return res.data.google?.googleAdsCustomerId;
  } catch (err) {
    return "";
  }
}

async function getInvitationStatus(auth, googleAdsCustomerId) {
  const res = await api.get(`platforms/tray/stores/${auth.store.id}/ads-invitation/${googleAdsCustomerId}`);
  const invitationData = res.data;

  if (!invitationData.results) return; // Return if invitation is accepted
  const lastInvitation = invitationData.results.length - 1;

  if (invitationData.results[lastInvitation].invitationStatus === "PENDING") return invitationData.results[lastInvitation].customerUserAccessInvitation;
  const resPending = await getInvitationPending(auth, googleAdsCustomerId);

  if (resPending.results && resPending.results[0]) return resPending.results[0].customerUserAccessInvitation;
  return invitationData.results[lastInvitation].customerUserAccessInvitation;
}

async function getInvitationPending(auth, googleAdsCustomerId) {
  const res = await api.get(`platforms/tray/stores/${auth.store.id}/ads-invitation/${googleAdsCustomerId}?status=PENDING`);
  return res.data;
}

function openSummaryAndMoveToPmax(next) {
  const summaryLink = "https://ads.google.com/aw/billing/summary";
  showGoogleBillingPopUp(summaryLink);
  next();
}

const openAndListenToInvitationLink = (adsWindowUrl, auth, next, googleAdsCustomerId) => {
  const adsWindow = showGoogleBillingPopUp(adsWindowUrl);
  let currentAttempt = 0;

  const maxAttempts = 360; // 360 attempts x 5 seconds = 30 minutes

  const interval = setInterval(async () => {
    if (currentAttempt > maxAttempts) {
      clearInterval(interval);
    }

    const invitationData = await getInvitationStatus(auth, googleAdsCustomerId);

    // Customer accept the invitation link
    if (!invitationData) {
      adsWindow.close();
      clearInterval(interval);
      next();
    }

    currentAttempt += 1;
  }, 5000);
};

async function sendNewInvitationLink(auth, email, ocid) {
  try {
    const res = await api.post(`platforms/tray/stores/${auth.store.id}/ads-invitation/${auth.store.google.googleAdsCustomerId}`, { email: email, ocid: ocid });
    const inviteLink = res.data;
    return { data: inviteLink };
  } catch (err) {
    if (err.response.data === "EMAIL_ADDRESS_ALREADY_HAS_ACCESS") return { error: err };
    return { data: "" };
  }
}

const getOcid = (originalInviteLink) => originalInviteLink.split("ocid=")[1];

function showGoogleBillingPopUp(url) {
  // Calculate popUp size
  var h = Math.max(800, window.screen.availHeight * 0.66); // try to use 66% of height, but no smaller than 800
  var w = Math.max(500, window.screen.availWidth * 0.5); // try to use 25% of width, but no smaller than 800

  // Find popUp center
  var windowLocation = {
    left: window.screen.availLeft + window.screen.availWidth / 2 - w / 2,
    top: window.screen.availTop + window.screen.availHeight / 2 - h / 2,
  };

  const config =
    "ModalPopUp" +
    ", toolbar=no" +
    ", scrollbars=no," +
    ", location=no" +
    ", statusbar=no" +
    ", menubar=no" +
    ", resizable=0" +
    ", width=" +
    w +
    ", height=" +
    h +
    ", left=" +
    windowLocation.left +
    ", top=" +
    windowLocation.top;

  return window.open(url, "Billing", config);
}
