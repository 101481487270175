/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import GoogleAccountsSection from "../../../components/SettingsPage/GoogleAccountsSection";

import Navigation from "../../../components/Dashboard/Navigation";
import HelpLink from "../../../components/HelpLink";
import CampaignsListingSection from "../../../components/SettingsPage/CampaignsListingSection";

import { useLocation } from 'react-router-dom'

function Settings() {
  const { search } = useLocation()

  const [selectedTab, setSelectedTab] = useState('accounts')

  useEffect(() => {
    if (search.includes('tab=accounts')) {
      setSelectedTab('accounts');
    } else {
      setSelectedTab('campaign');
    }
  }, [search]);

  return (
    <>
      <Helmet>
        <title>Configurações - Traygle</title>
        <meta name="description" content="Configurações - Traygle" />
        <meta property="og:title" content="Configurações - Traygle" />
        <meta property="og:description" content="Configurações - Traygle" />
      </Helmet>

      <main role="main">
        <section className="grid-container">
          <div className="settings">
            <Navigation currentSection="settings" />

            <section class="settings__visao--geral">
              <h1 style={{ display:"none" }}>Configurações</h1>

              <main class="grid-x">                
                {/* <ul class="dashboard__nav">
                  <li className={selectedTab === 'accounts' ? 'active' : ''} onClick={() => setSelectedTab('accounts')}>
                    <a>Contas conectadas</a>
                  </li>
                  <li className={selectedTab === 'campaign' ? 'active' : ''} onClick={() => setSelectedTab('campaign')}>
                    <a>Campanha</a>
                  </li>
                </ul> */}
                {selectedTab === 'accounts' ?
                  <GoogleAccountsSection /> :
                  <CampaignsListingSection />
                }
              </main>
            </section>

            <HelpLink />
          </div>
        </section>
      </main>
    </>
  );
}

export default Settings;
