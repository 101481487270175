import { Button } from "../Button";
import { Modal } from "../Modal";

import "./styles.scss"

export function MissingScopesModal({ isOpen, closeModal, missingScopes = [], onGoogleSignInButtonClick }) {
    return (
        <Modal isOpen={isOpen}>
            <div className="auth-modal-title">
                <p>Falta de permissões</p>
            </div>
            <div className="auth-modal-body">
                <p className="red-text">A sua integração <b>só poderá continuar após a permissão de acesso</b> aos seguinte itens:</p>

                <ul>
                    {missingScopes.map(scope => <li>{scope}</li>)}
                </ul>

                <p>Esse passo é muito importante.</p>
                <p className="red-text">Sem permitir que a Tray acesse tais dados, não será possível integrar a sua loja ao Google Shopping.</p>
            </div>
            <div className="auth-modal-footer">
                <div className="widget__connect__buttons">
                    <Button onClick={onGoogleSignInButtonClick}>
                        Abrir tela de permissões de acesso
                    </Button>
                </div>
            </div>
        </Modal>
    )
}