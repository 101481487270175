import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { useWizard } from "../../hooks/useWizard";
import { api } from "../../services/api";

import { Button } from "../Button";
import { Modal } from "../Modal";

import SpinnerSvgGif from "../../assets/img/icons/spinner.svg";
import GoogleAdImg from "../../assets/img/content/google-ad.png";
import GoogleImg from "../../assets/img/content/google.png";
import MerchantImg from "../../assets/img/content/merchant.png";

import "./styles.scss"
import { wizardSteps } from "../../contexts/WizardContext";

export function DisconnectAccountsButton({ forceRedirect }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <DisconnectConfirmationModal 
                isOpen={isModalOpen} 
                closeModal={() => setIsModalOpen(false)} 
                forceRedirect={forceRedirect}
            />
            <button
                onClick={() => setIsModalOpen(true)}
                className="settings__button disconnect"
            >
                Desconectar contas
            </button>
        </>
    );
}

function DisconnectConfirmationModal({ isOpen, closeModal, forceRedirect }) {
    const auth = useAuth();
    const wizard = useWizard();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    async function handleDisconnectAccount() {
        setLoading(true);
        try {
            wizard.updateCurrentStep(wizardSteps.START);
            await api.post("/google/accounts/unlink");
            
            if(forceRedirect) {
                history.push("/wizard");
            }
        } catch {
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal isOpen={isOpen} closeModal={closeModal}>
            <div className="disconnect-title">
                <p>Você tem certeza de que deseja desconectar todas as contas?</p>
                <p className="close" onClick={closeModal}>x</p>
            </div>
            <div className="disconnect-body">
                <p>Esta ação desconectará as seguintes contas:</p>

                <div className="resume__itens--container">
                    {auth.primaryEmail &&
                        <div className="resume__item">
                            <div className="products__item__img">
                                <img src={GoogleImg} alt="Google" />
                            </div>
                            <div className="resume__item__user">
                                <p>Conta Google:</p>
                                <p>{auth.primaryEmail}</p>
                            </div>
                        </div>
                    }

                    {auth.merchantAccount?.id &&
                        <div className="resume__item">
                            <div className="resume__item__img">
                                <img src={MerchantImg} alt="Merchant" />
                            </div>
                            <div className="resume__item__user">
                                <p>Conta Merchant Center:</p>
                                <p>{auth.merchantAccount?.name} - {auth.merchantAccount?.id}</p>
                            </div>
                        </div>
                    }

                    {auth.googleAdsAccount?.id &&
                        <div className="resume__item" style={{ alignItems: "center" }}>
                            <div className="resume__item__img">
                                <img src={GoogleAdImg} alt="Google Ads" />
                            </div>
                            <div className="resume__item__user">
                                <p>Conta Google Ads:</p>
                                <p>{auth.googleAdsAccount?.name} - {auth.googleAdsAccount?.id}</p>
                            </div>
                        </div>
                    }
                </div>

                <p>Ao confirmar esta ação, você será levado para o começo da integração, necessitando conectar novamente as contas para finalizar a instalação.</p>
            </div>
            <div className="disconnect-footer">
                <div className="widget__connect__buttons">
                    {loading ? (
                        <div style={{ textAlign: "center", width: "100%" }}>
                            <Button onClick={() => null} className="confirm-button">
                                <img src={SpinnerSvgGif} alt="Animation spinner" />{" "}
                                Desconectando...
                            </Button>
                        </div>
                    ) : (
                        <>
                            <Button onClick={closeModal} className="cancel-button">
                                Quero continuar conectado
                            </Button>
                            <Button onClick={handleDisconnectAccount} className="confirm-button">
                                Confirmar desconexão de contas
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    )
}