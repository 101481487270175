import { useEffect, useState } from 'react';
import styles from "./styles.module.scss";
import { FaCheck } from "react-icons/fa";

const Steps = ({ step, showPresentation }) => {
  const [stepsNames, setStepsNames] = useState([ "Campanha", "line", "Pagamento", "line", "Resumo" ]);

  useEffect(() => {
    const isIntegrarAdded = stepsNames.some((step) => step === "Integrar")
    if (isIntegrarAdded) {
      return;
    }
    if (showPresentation) {
      setStepsNames(["Integrar", "line", ...stepsNames]);
    }
  }, [showPresentation, stepsNames])

  function handleStepStatus(index) {
    if(step === index) return `${styles.step} ${styles.current}`;
    if(step > index) return `${styles.step} ${styles.passed}`;
    return styles.step;
  }


  function getIndex(name) {
    const namesWithoutIndex = stepsNames.filter((value) => value !== "line");
    return namesWithoutIndex.findIndex((value) => value === name);
  }

  return (
    <div className={ styles.container }>
      { stepsNames.map((name, index) => {
        
        if(name === "line") return <div className={ styles.line } key={ index }/>
        
        return (
        <div 
          className={ handleStepStatus(getIndex(name)) } 
          key={ index }
        >
          <div className={ styles.step_number }>
            {getIndex(name) < step ? (<FaCheck color="white"/>) : (<>{getIndex(name) + 1}</>)}
          </div>
          <p className={ styles.name }>{ name }</p>
        </div>
      )})}
    </div>
  )
}

export default Steps;