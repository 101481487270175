import { IoClose } from "react-icons/io5";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

const Header = ({ displayCloseBtn = true }) => {
  return (
    <div className={styles.header}>
      <div>
        <h1>Criar campanha Performance Max</h1>

        {displayCloseBtn && <Link to="/">
          <IoClose className={styles.close_icon} color="#6B7280" size={30} />
        </Link>}
      </div>
    </div>
  );
};

export default Header;
