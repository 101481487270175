import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import ReactCodeInput from "react-code-input";
import { toast } from "react-toastify";

import { ProgressIndicator } from "../../../components/Wizard/ProgressIndicator";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { Tooltip } from "../../../components/Tooltip";
import { useAuth } from "../../../hooks/useAuth";
import { useWizard } from "../../../hooks/useWizard";
import { wizardSteps } from "../../../contexts/WizardContext";
import { api } from "../../../services/api";

import SmallCheckImg from "../../../assets/img/icons/small-check.svg";
import TipsImg from "../../../assets/img/icons/tips.svg";
import MerchantImg from "../../../assets/img/content/merchant.png";
import ExclamationMarkImg from "../../../assets/img/icons/exclamation-mark.svg";

import styles from "./styles.module.scss";
import PhoneInput from "../../../components/PhoneInput";
import { UpdateAddress } from "../../../components/UpdateAddress";

let countdownTimeout;
const countdownValue = 60; // seconds

export default function MerchantCenterPage() {
  const auth = useAuth();
  const wizard = useWizard();

  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const [time, setTime] = useState(countdownValue);
  const [code, setCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sendCodeThrow, setSendCodeThrow] = useState(
    auth.store?.google?.phoneVerification?.verificationMethod || "SMS"
  );
  const [submiting, setSubmiting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const seconds = String(time);

  const [address, setAddress] = useState({});

  useEffect(() => {
    setAddress({
      street: auth.user?.businessInformation?.address?.streetAddress,
      postalCode: auth.user?.businessInformation?.address?.postalCode,
      city: auth.user?.businessInformation?.address?.locality,
      state: auth.user?.businessInformation?.address?.region
    })
  }, [auth.store]);

  useEffect(() => {
    if (isModalOpen && time > 0) {
      countdownTimeout = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    } else if (time === 0) {
      clearTimeout(countdownTimeout);
    } else if (!isModalOpen) {
      clearTimeout(countdownTimeout);
      setTime(countdownValue);
    }
  }, [isModalOpen, time]);

  async function handleCreatePhoneVerification(showLoading, isResend) {
    try {
      setTime(countdownValue);
      setSubmiting(showLoading);

      await api.post(
        `google/merchant_center/accounts/${auth.merchantAccount?.id}/request_phone_verification`,
        {
          phoneNumber: phoneNumber.replace(/\D+/g, ""),
          phoneVerificationMethod: sendCodeThrow,
        }
      );
      setIsModalOpen(true);
    } catch {
    } finally {
      setSubmiting(false);
    }
  }

  function handleCloseModal() {
    setSubmiting(false);
    setIsModalOpen(false);
  }

  async function handleVerifyPhoneNumber() {
    if (code.length === 6) {
      try {
        setSubmiting(true);
        await api.post(
          `google/merchant_center/accounts/${auth.merchantAccount?.id}/verify_phone_number`,
          {
            code,
          }
        );
        setIsModalOpen(true);

        toast.success("Telefone verificado com sucesso.");

        wizard.updateCurrentStep(wizardSteps.GOOGLE_ADS_CONNECTION);
        // wizard.updateCurrentStep(wizardSteps.ACCOUNTS_REVIEW);
      } catch (err) {
        if (err?.response?.data?.error?.code === "4206") {
          toast.error(
            "O código de verificação está inválido. Verifique-o e tente novamente."
          );
        }

        if (err?.response?.data?.error?.code === "4207") {
          toast.error(
            "Código de verificação expirado. Por favor, solicite um novo código."
          );
        }
      } finally {
        setSubmiting(false);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Merchant Center - Traygle</title>
        <meta
          name="description"
          content="Antes de começarmos a selecionar produtos, precisamos verificar se você tem acesso ao endereço e propriedade do site. Assim poderemos ligar sua conta Merchant Center ao endereço da sua loja."
        />
        <meta property="og:title" content="Merchant Center  - Traygle" />
        <meta
          property="og:description"
          content="Antes de começarmos a selecionar produtos, precisamos verificar se você tem acesso ao endereço e propriedade do site. Assim poderemos ligar sua conta Merchant Center ao endereço da sua loja."
        />
      </Helmet>

      <div className="merchant-center">
        <ProgressIndicator currentStep={2} />

        <div
          className="widget widget--merchant"
          style={{
            paddingBottom: "1.5rem",
          }}
        >
          <h1 className="widget__title widget__title--bold">
            Configurar conta Google Merchant Center
          </h1>

          <p className="widget__text">
            Antes de começarmos a selecionar produtos, precisamos verificar os
            seu dados. Assim poderemos ligar sua conta Merchant Center ao
            endereço da sua loja. Vamos lá?
          </p>
        </div>

        <div className="account">
          <div className={styles.mainContent}>
            <div >
              <div className="account__block">
                <div className="account__block__img">
                  <img src={MerchantImg} alt="Merchant center" />
                </div>
                <div className="account__block__text">
                  <p>Conta Merchant Center:</p>
                  <p>
                    {auth.merchantAccount?.name} - {auth.merchantAccount?.id}
                    <span className="ml-0.5">
                      <img src={SmallCheckImg} alt="Autenticado" />
                    </span>
                    <Tooltip className="ml-0.5" id="mc_account" message="Conta que será utilizada para enviar o feed de produtos">
                      <img src={TipsImg} alt="Dicas" />
                    </Tooltip>
                  </p>
                </div>
              </div>

              <div className="account__block account__block--store">
                <div className="account__block__text">
                  <p>Nome da sua loja</p>
                  <p>{auth.store?.name}</p>
                </div>
              </div>

              <div className="account__block account__block--store">
                <div className="account__block__text">
                  <p>
                    URL da loja
                    <Tooltip
                      className="ml-0.5"
                      id="store_url"
                      message="URL da sua loja virtual que  será vinculada à sua campanha"
                    >
                      <img src={ExclamationMarkImg} alt="Dicas" />
                    </Tooltip>
                  </p>
                  <p>
                    <a
                      href={auth.user?.websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.websiteUrl}
                    >
                      {auth.user?.websiteUrl}
                    </a>
                  </p>
                </div>
              </div>

              <div className="account__block account__block--store">
                <div className="account__block__text">
                  <p>
                    Endereço da loja
                    <Tooltip
                      className="ml-0.5"
                      id="store_address"
                      message="Endereço físico da sua loja ou que está relacionado ao seu CNPJ"
                    >
                      <img src={TipsImg} alt="Dicas" />
                    </Tooltip>
                  </p>
                  <p>
                    {address?.street} &nbsp;
                    <UpdateAddress
                      address={address}
                      setAddress={setAddress}
                    />
                    <br />
                    {address?.city} - {address?.state}
                    <br />
                    {address?.postalCode}
                  </p>
                </div>
              </div>

              <hr />
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  handleVerifyPhoneNumber();
                }}
                className="account__form"
              >
                <div className="account__form__item">
                  <label htmlFor="phone">
                    Telefone para verificação
                    <Tooltip
                      className="ml-0.5"
                      id="store_phone_number"
                      message="Telefone celular que será enviado um código para autenticar sua conta. Você pode escolher em receber através de SMS ou Ligação."
                    >
                      <img src={ExclamationMarkImg} alt="Dicas" />
                    </Tooltip>
                  </label>
                  <PhoneInput
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="(99) 99999-9999"
                    value={phoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </div>
                <div className="account__form__item account__form__item--checkbox">
                  <p>Receber código de verificação por:</p>
                  <div className="radio-group">
                    <div className="radio-group__item">
                      <input
                        type="radio"
                        id="sms"
                        name="verify"
                        value="SMS"
                        checked={sendCodeThrow === "SMS"}
                        onChange={(event) => {
                          setSendCodeThrow(event.target.value);
                        }}
                      />{" "}
                      <label className="radio" htmlFor="sms">
                        SMS
                      </label>
                    </div>
                    <div className="radio-group__item">
                      <input
                        type="radio"
                        id="call"
                        name="verify"
                        value="PHONE_CALL"
                        checked={sendCodeThrow === "PHONE_CALL"}
                        onChange={(event) => {
                          setSendCodeThrow(event.target.value);
                        }}
                      />{" "}
                      <label className="radio" htmlFor="call">
                        Ligação Telefônica
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className={styles.checkbox}>
              <label className="control control--checkbox center-box">
                <input
                  type="checkbox"
                  id="confirm"
                  name="confirm"
                  className="confirmed"
                  checked={isTermsChecked}
                  onChange={() => {
                    setIsTermsChecked((isTermsChecked) => !isTermsChecked);
                  }}
                />
                <div className="control__indicator"></div>

                <span>
                  Informações de contato da loja estão corretas
                </span>
              </label>
            </div>

            <div className="account__form__item account__form__item--buttons">
              <Button onClick={() => wizard.updateCurrentStep(wizardSteps.MERCHANT_CENTER_CONNECTION)}>Voltar</Button>
              <Button
                disabled={phoneNumber === "" || !isTermsChecked}
                isSubmitting={submiting}
                submitingMessage="Enviando código"
                onClick={() => {
                  if (!address || !address.street) {
                    toast.error(
                      "Por favor, preencha o endereço da sua loja."
                    );
                    return;
                  }
                  handleCreatePhoneVerification(true)
                }}
              >
                Verificar telefone
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} closeModal={handleCloseModal}>
        <div className="verify-phone">
          <div className="widget" style={{ padding: "3.75rem 2rem" }}>
            <div className="widget__code-info">
              <label className="widget__title widget__title--verify">
                Verifique o seu número de telefone.
              </label>

              <p className="widget__code-warning">
                O código de 6 dígitos foi enviado para o número:
                <span className="widget__code">{phoneNumber}</span>
              </p>
            </div>
            <div className="widget__code-group">
              <p>Insira o código recebido:</p>
              <div className="input-group">
                <ReactCodeInput
                  type="number"
                  value={code}
                  onChange={setCode}
                  placeholder="0"
                  fields={6}
                />

                {time === 0 ? (
                  <Button onClick={() => handleCreatePhoneVerification(false)}>
                    <span className="content-phone">Enviar um novo código</span>
                  </Button>
                ) : (
                  <span>
                    Você pode solicitar um novo código em {seconds} segundos
                  </span>
                )}
              </div>
              <div className="widget__code-group__buttons">
                <Button onClick={handleCloseModal}>Voltar</Button>

                <Button
                  id="btn-phone"
                  isSubmitting={submiting}
                  submitingMessage="Verificando telefone"
                  disabled={code.length < 6}
                  onClick={handleVerifyPhoneNumber}
                >
                  Verificar telefone
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
