import { useState } from "react";

import { Button } from "../Button";
import { Sidebar } from "../Sidebar";
import { useAuth } from "../../hooks/useAuth";
import { replaceHttp } from "../../utils/format";

import styles from "./hasAccount.module.scss";
import { HiOutlineArrowTopRightOnSquare } from "react-icons/hi2";

export function OverrideSiteClaiming({
    setShowOverrideSiteClaiming,
    showOverrideSiteClaiming,
    handleOverrideSiteClaiming
}) {
    const auth = useAuth();

    const [acceptedOverride, setAcceptedOverride] = useState(false);

    return (
        <Sidebar
            isSidebarOpen={showOverrideSiteClaiming}
            onOpen={() => setShowOverrideSiteClaiming(true)}
            onClose={() => setShowOverrideSiteClaiming(false)}
            className={styles.verifyWebSiteContentContainer}
            sidebarClass={styles.sidebar}
        >
            <div>
                <h4 className={styles.title}>
                    URL já vinculada à outra conta
                </h4>
                <p className={styles.descriptionBold}> 
                    A URL <span>{replaceHttp(auth.store?.storeUrl)}</span> já está
                    vinculada a outra conta Merchant Center.
                </p>
                <p className={styles.description}>
                    A URL da sua loja já está vinculada a outra conta Merchant Center.
                    Não tem problema! É possível usar a URL nesta conta <br />
                    <br />
                    Ao confirmar, você autoriza a Tray a modificar as configurações
                    das suas contas Merchant Center.&nbsp;
                    <a href="https://www.tray.com.br/central-google-shopping/#faq" target="_blank">
                        Saiba mais <i><HiOutlineArrowTopRightOnSquare /></i>
                    </a>
                </p>
            </div>

            <div className={styles.buttonsAndCheckbox}>
                <label className="control control--checkbox center-box">
                    <input
                        type="checkbox"
                        id="confirm"
                        name="confirm"
                        className="confirmed"
                        checked={acceptedOverride}
                        onChange={() => {
                            setAcceptedOverride((oldVersion) => !oldVersion);
                        }}
                    />
                    <div className="control__indicator"></div>

                    <span>
                        Entendo que a minha configuração de conta Google Merchant
                        Center será modificada pela integração da Tray.
                    </span>
                </label>
                <div className={styles.buttonsContainer}>
                    <Button
                        className={styles.cancelConfiguration}
                        onClick={() => {
                            setShowOverrideSiteClaiming(false);
                        }}
                        >
                        Cancelar
                    </Button>
                    <Button
                        className={styles.acceptConfiguration}
                        disabled={!acceptedOverride}
                        onClick={handleOverrideSiteClaiming}
                        >
                        Aceitar configuração e atualizar URL
                    </Button>
                </div>
            </div>
        </Sidebar>
    );
}
