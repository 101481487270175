/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { FaPause, FaPlay, FaEdit, FaList } from "react-icons/fa";
import { toast } from "react-toastify";

import { api } from "../../services/api";
import { useAuth } from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";
import { useWizard } from "../../hooks/useWizard";
import { PauseCampaign } from "../PauseCampaign";

import SpinnerBlueSvgGif from "../../assets/img/icons/spinner-blue.svg";
import { Link } from "react-router-dom";
import { Button } from "../Button";
import { wizardSteps } from "../../contexts/WizardContext";
import LogsModal from "../LogsModal";

function CampaignsListingSection() {
    const auth = useAuth();
    const history = useHistory();
    const wizard = useWizard();

    const [campaignData, setCampaignData] = useState({
        name: "",
        dailyAmountInCents: 0,
        filter: { operation: 'default' },
        status: "ENABLED",
    });

    const [isLoadingCampaign, setIsLoadingCampaign] = useState(true);
    const [isActivatingCampaign, setIsActivatingCampaign] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (
            auth.store === null ||
            !auth.store.google?.campaignId ||
            !auth.store.google?.googleAdsCustomerId
        )
            return;

        loadCampaignData();
    }, [auth.store]);

    async function loadCampaignData() {
        try {
            setIsLoadingCampaign(true);

            const campaignId = auth.store.google.campaignId;
            const customerId = auth.store.google.googleAdsCustomerId;

            await api
                .get(`/google/ads/customers/${customerId}/campaigns/${campaignId}`)
                .then((res) => {
                    setCampaignData(res.data);
                });
        } catch {
        } finally {
            setIsLoadingCampaign(false);
        }
    }

    function changeCampaignData(values) {
        setCampaignData((old) => ({ ...old, ...values }));
    }

    function getFiltersDescription(filter) {
        if (!filter || !filter.operation || filter.type === 'default') {
            return 'Todos os produtos selecionados';
        }

        const count = Array.isArray(filter[filter.type]) ? filter[filter.type].length : 0;

        let type = '';
        switch (filter.type) {
            case 'brand': type = 'marcas'; break;
            case 'category': type = 'categorias'; break;
            case 'product': type = 'produtos'; break;
            default: break;
        }

        let operation = '';
        if (filter.operation === 'allow') {
            operation = type === 'produtos' ? 'selecionados' : 'selecionadas';
        }

        if (filter.operation === 'disallow') {
            operation = type === 'produtos' ? 'removidos' : 'removidas';
        }

        if (count === 1) {
            type = type.slice(0, -1);
            operation = operation.slice(0, -1);
        }

        return `${count} ${type} ${operation}`;
    }

    async function handleRestartCampaign() {
        setIsActivatingCampaign(true);
        if (
            auth.store === null ||
            !auth.store.google?.campaignId ||
            !auth.store.google?.googleAdsCustomerId
        )
            return;
        const campaignId = auth.store.google.campaignId;
        const customerId = auth.store.google.googleAdsCustomerId;

        try {
            await api.put(
                `/google/ads/customers/${customerId}/campaigns/${campaignId}`,
                {
                    status: "ENABLED",
                }
            );

            toast.success(`Campanha ativada com sucesso.`);
            setCampaignData((old) => ({ ...old, status: "ENABLED" }));
        } catch {
        } finally {
            setIsActivatingCampaign(false);
        }
    }

    return (
        <>
            {(auth.store === null || !auth.store.google?.campaignId) ? (
                <section class="cell small-12 settings__campaign--section">
                    <div className="text-align-center" style={{ display: 'block' }}>
                        <p>Você não ainda não possui nenhuma campanha.</p>
                        <Button
                            className="settings__maintainButton"
                            onClick={() => {
                                wizard.updateCurrentStep(wizardSteps.SSC_CREATION);
                                history.push("/wizard");
                            }}
                        >
                            Criar campanha Performance Max
                        </Button>
                    </div>

                </section>
            ) : (
                <section class="cell small-12 settings__campaign--section">
                    <div>
                        <h3>Campanha Performance Max</h3>
                        <a href="https://ads.google.com/aw/billing/summary" target='_blank'>Adicionar saldo</a>
                    </div>

                    {isLoadingCampaign ? (
                        <div className="text-align-center" style={{ display: 'block' }}>
                            <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
                        </div>
                    ) : (
                        <table>
                            <thead>
                                <tr>
                                    <th>Nome da campanha</th>
                                    <th>Itens da campanha</th>
                                    <th>Status</th>
                                    {/* <th>Restrições de preço</th> */}
                                    <th class="text-align-center">Ações</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td class="campaign">
                                        <div>
                                            <p class="campaign-name">
                                                {campaignData.name}
                                            </p>
                                            {/* <div>
                                        <p>340 produtos</p>
                                    </div> */}
                                        </div>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>{getFiltersDescription(campaignData.filter)}</li>
                                        </ul>
                                    </td>
                                    <td>
                                        <span class={`status ${campaignData.status === 'ENABLED' ? 'enabled' : 'disabled'}`}>
                                            {campaignData.status === 'ENABLED' ? 'Ativo' : 'Inativo'}
                                        </span>
                                    </td>
                                    {/* <td>
                                <ul>
                                    <li>Preço mínimo: R$10,00</li>
                                    <li>Preço máximo: -</li>
                                </ul>
                            </td> */}

                                    <td class="actions">
                                        {isActivatingCampaign ? (
                                            <img src={SpinnerBlueSvgGif} alt="Animation spinner" height={25} width={25} />
                                        ) : (
                                            <>
                                                <button>
                                                    <i>
                                                        {campaignData.status === 'ENABLED' ?
                                                            <PauseCampaign
                                                                changeCampaignData={changeCampaignData}
                                                                element={<FaPause />}
                                                            /> :
                                                            <FaPlay onClick={() => handleRestartCampaign()} />}
                                                    </i>
                                                </button>
                                                <button>
                                                    <Link to={`/campaigns/${auth.store.google?.campaignId}`}><i><FaEdit /></i></Link>
                                                </button>
                                                <button onClick={() => setIsModalOpen(true)}>
                                                    <i><FaList /></i>
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </section>
            )}

            <LogsModal
                handleClose={() => setIsModalOpen(!isModalOpen)}
                isOpen={isModalOpen}
                scope='campaign'
                scopeId={auth.store.google.campaignId}
                campaign={campaignData}
            />
        </>
    )
}

export default CampaignsListingSection;