import { useWizard } from "../../hooks/useWizard";
import { wizardSteps } from "../../contexts/WizardContext"

import ConectedAccountsPage from "./ConectedAccountsPage";
import CreateCampaignPage from "./CreateCampaignPage";
import GoogleAdsAccountPage from "./GoogleAdsAccountPage";
import GoogleContaConectadaPage from "./GoogleContaConectadaPage";
import GooglePage from "./GooglePage";
import MerchantCenterPage from "./MerchantCenterPage";
import ResumoContasPage from "./ResumoContasPage";
import SmartShoppingPage from "./SmartShoppingPage";
import Start from "./StartPage";
import HelpLink from "../../components/HelpLink"

export default function Wizard() {
    const wizard = useWizard();

    const getStep = () => {
        switch (wizard.currentStep) {
            case wizardSteps.START: return <Start />
            case wizardSteps.GOOGLE_ACCOUNT_CONNECTION: return <GooglePage />
            case wizardSteps.MERCHANT_CENTER_CONNECTION: return <GoogleContaConectadaPage />
            case wizardSteps.PHONE_VERIFICATION: return <MerchantCenterPage />
            case wizardSteps.ACCOUNTS_REVIEW: return <ResumoContasPage />
            case wizardSteps.GOOGLE_ADS_CONNECTION: return <GoogleAdsAccountPage />
            case wizardSteps.ACCOUNTS_CONNECTION_SUCCESS: return <ConectedAccountsPage />
            case wizardSteps.SSC_PRESENTATION: return <SmartShoppingPage />
            case wizardSteps.SSC_CREATION: return <CreateCampaignPage />
            default: return <div />;
        }
    }

    const isWizard = wizard.currentStep !== wizardSteps.START;

    return (
        <>
            {getStep()}
            {isWizard && <HelpLink isWizard={isWizard} />}
        </>
    )
}