import { ProgressIndicator } from "../../../components/Wizard/ProgressIndicator";
import GoogleSignInButton from "../../../components/GoogleSignInButton";

import GoogleImg from "../../../assets/img/content/google.png";
import { useGoogleAuth } from "../../../hooks/useGoogleAuth";

import styles from "./styles.module.scss";
import Helmet from "react-helmet";

export default function GooglePage() {
  const googleAuth = useGoogleAuth();

  return (
    <>
      <Helmet>
        <title>Google - Traygle</title>
        <meta
          name="description"
          content="Associar a sua conta Google irá permitir a sincronização de seus produtos com o Merchant Center, criar anúncios com Google Ads e também a criação de campanhas."
        />
        <meta property="og:title" content="Google  - Traygle" />
        <meta
          property="og:description"
          content="Associar a sua conta Google irá permitir a sincronização de seus produtos com o Merchant Center, criar anúncios com Google Ads e também a criação de campanhas."
        />
      </Helmet>

      <div className="google-connected">
        <ProgressIndicator currentStep={1} />

        <div className="widget widget--google">
          <h1 className="widget__title" style={{ color:"#374151", fontWeight:700 }}>
            Para começar, conecte sua conta Google à sua loja.
          </h1>

          <button className={ styles.google_button } onClick={ () => {
              googleAuth.openOnlyGoogle();
              googleAuth.showScopesInfoModal();
          }}>
            <img 
              src={ GoogleImg } 
              alt="Google" 
            />
            Fazer login com o google
          </button>

          <div className={ styles.google_create_account }>
            Não tenho email no Google. 
            <a 
              href="https://accounts.google.com/" 
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft:"5px"}}
            >
              Clique aqui para criar
            </a>
          </div>
          {/* <div className="widget__connect widget__connect--google">
            <div className="widget__connect__buttons">
              <GoogleSignInButton onClick={() => googleAuth.showScopesInfoModal()}/>
            </div>
          </div>
          <div className="widget__content widget__content--google">
            <h2>Conectar a sua conta Google permitirá: </h2>
            <ul>
              <li>Sincronizar seus produtos com o Merchant Center.</li>
              <li>Criar anúncios com Google Ads.</li>
              <li>Criar e gerenciar sua campanha Performance Max.</li>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
}
