import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { FaRedoAlt } from "react-icons/fa";
import { HiOutlineArrowTopRightOnSquare } from "react-icons/hi2";
import ReviewRequestModal from "../ReviewRequestModal";
import { formatShortDate } from "../../utils/format";
import { useHistory } from "react-router-dom";

export function ReviewRequest() {
    const knowMoreUrl = 'https://support.google.com/merchants/answer/6109185?hl=pt-BR';

    const auth = useAuth();
    const history = useHistory();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [canRequestReview, setCanRequestReview] = useState(false);

    useEffect(() => {
        setCanRequestReview(!!auth?.store?.canRequestMerchantCenterReview)
    }, [auth?.store]);

    const goToLink = (link) => {
        history.push(link);
        window.scroll({ top: 0 });
    }

    return (auth?.issues?.accountLevelIssues?.length === 0 ? (
        <div />
    ) : (
        <>
            <section class="cell small-12 review-request__section">
                <i><FaRedoAlt /></i>
                <h3>Acelerar revisão</h3>

                {canRequestReview ? (
                    <main>
                        <p>
                            Antes de solicitar a revisão, verifique se você corrigiu todos os problemas de <a onClick={() => goToLink('/problems?tab=products')}>Produto</a> e <a onClick={() => goToLink('/problems?tab=account')}>Conta</a> listados.<br />
                            Se não, você pode ter que esperar até 7 dias para realizar uma nova revisão. <a href={knowMoreUrl} target="_blank">Saiba mais</a>
                            <i><HiOutlineArrowTopRightOnSquare /></i>
                        </p>
                        <button onClick={() => setIsModalOpen(!isModalOpen)}>Solicitar revisão manual</button>
                    </main>
                ) : (
                    <main>
                        <p>
                            {auth?.store?.lastMerchantCenterReviewRequestedAt &&
                                <>
                                    Você solicitou a revisão em {formatShortDate(auth?.store?.lastMerchantCenterReviewRequestedAt)}.
                                </>
                            }
                            <br />
                            Pelas políticas do Google somente é possível fazer a solicitação a cada 7 dias. <a href={knowMoreUrl} target="_blank">Saiba mais</a>
                            <i><HiOutlineArrowTopRightOnSquare /></i>
                        </p>

                        {auth?.store?.canRequestMerchantCenterReviewAt ? (
                            <button disabled>Proxima solicitação após {formatShortDate(auth?.store?.canRequestMerchantCenterReviewAt)}</button>
                        ) : (
                            <button disabled>Solicitar revisão manual</button>
                        )}
                    </main>
                )}
            </section>

            <ReviewRequestModal
                handleClose={() => setIsModalOpen(!isModalOpen)}
                isOpen={isModalOpen}
            />
        </>
    ))
}
