/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { Modal } from "../Modal";
import { FaSearch, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { api } from "../../services/api";
import { Button } from "../Button";
import SpinnerBlueSvgGif from "../../assets/img/icons/spinner-blue.svg";
import InfiniteScroll from 'react-infinite-scroller';
import { flatMappedCategories, mapTrayCategories } from "../../utils/mappers";
import { formatMoney } from "../../utils/format";

const modalSettings = operation => ({
    default: {},
    brand: {
        header: operation === 'allow' ? 'Selecionar marcas' : 'Restringir marcas',
        optionsTitle: operation === 'allow' ?
            'Selecione as marcas para a campanha' :
            <span>Selecione as marcas para <b>não incluir</b> na campanha</span>,
        selectedOptionsTitle: operation === 'allow' ? 'Marcas selecionadas' : 'Marcas restritas',
        emptySelectedOptionsTitle: operation === 'allow' ? 'Nenhuma marca selecionada' : 'Nenhuma restrição de marca',
        searchInputPlaceholder: 'Nome da marca',
        apiUrl: (storeId, searchTerm) => {
            let url = `/platforms/tray/api_forward/${storeId}/brands?sort=brand_asc`;
            if (searchTerm) url += `&brand=%${searchTerm}%`;
            return url;
        },
        optionsMapper: (data) => {
            let mappedOptions = [];
            data.Brands.forEach(brand => {
                mappedOptions.push({ id: brand.Brand.id, name: brand.Brand.brand })
            });
            return mappedOptions;
        }
    },
    category: {
        header: operation === 'allow' ? 'Selecionar categorias' : 'Restringir categorias',
        optionsTitle: operation === 'allow' ?
            'Selecione as categorias para a campanha' :
            <span>Selecione as categorias para <b>não incluir</b> na campanha</span>,
        selectedOptionsTitle: operation === 'allow' ? 'Categorias selecionadas' : 'Categorias restritas',
        emptySelectedOptionsTitle: operation === 'allow' ? 'Nenhuma categoria selecionada' : 'Nenhuma restrição de categoria',
        searchInputPlaceholder: 'Nome da categoria',
        apiUrl: (storeId) => {
            return `/platforms/tray/api_forward/${storeId}/categories/tree?`;
        },
        optionsMapper: (data) => {
            let mappedOptions = mapTrayCategories(data.Category);
            mappedOptions = flatMappedCategories(mappedOptions);
            return mappedOptions;
        }
    },
    product: {
        header: operation === 'allow' ? 'Selecionar produtos' : 'Restringir produtos',
        optionsTitle: operation === 'allow' ?
            'Selecione os produtos para a campanha' :
            <span>Selecione os produtos para <b>não incluir</b> na campanha</span>,
        selectedOptionsTitle: operation === 'allow' ? 'Produtos selecionados' : 'Produtos restritos',
        emptySelectedOptionsTitle: operation === 'allow' ? 'Nenhum produto selecionada' : 'Nenhuma restrição de produto',
        searchInputPlaceholder: 'Nome ou referência',
        apiUrl: (storeId, searchTerm) => {
            let url = `/platforms/tray/api_forward/${storeId}/products?sort=name_asc`;
            if (searchTerm) url = `/platforms/tray/api_forward/${storeId}/search?sort=name_asc&query=${searchTerm}`;
            return url;
        },
        optionsMapper: (data) => {
            let mappedOptions = [];
            data.Products.forEach(product => {
                mappedOptions.push({
                    id: product.Product.id,
                    name: product.Product.name,
                    price: product.Product.price,
                    reference: product.Product.reference
                })
            });
            return mappedOptions;
        }
    }
})

function FilterOptionsModal({ campaign, changeCampaignData, handleClose, isOpen }) {
    const auth = useAuth();
    const scrollRef = useRef(null);

    const [searchTerm, setSearchTerm] = useState(null);

    const [isSaving, setIsSaving] = useState(false);
    const [isLoadingOptions, setIsLoadingOptions] = useState(false);

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [hasMoreOptions, setHasMoreOptions] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    const [filterType, setFilterType] = useState(null);
    const [filterOperation, setFilterOperation] = useState(null);

    const [settings, setSettings] = useState({});

    useEffect(() => {
        const filter = campaign && campaign.filter;
        const values = filter && filter[filter.type];

        // Reset variables
        setSelectedOptions(values || []);
        setSearchTerm(null);
        setOptions([]);
        setHasMoreOptions(true);
        setCurrentPage(1);

        setFilterType(filter.type);
        setFilterOperation(filter.operation);

        setSettings(modalSettings(filter.operation)[filter.type || 'default']);
    }, [isOpen]);

    const selectOption = (option) => {
        const newSelectedOptions = [...selectedOptions, option];
        setSelectedOptions(newSelectedOptions);
    }

    const removeSelectedOption = (optionId) => {
        const filteredSelectedOptions = selectedOptions.filter(o => o.id !== optionId);
        setSelectedOptions(filteredSelectedOptions);
    }

    const getOptions = async (page) => {
        try {
            // The max number of resources that is going to be fetched from the API
            const LIMIT = 10;

            // The category endpoint does not have pagination and brings all
            // the categories at once. So we need to block requests to pages
            // above 1 to avoid duplicated results.
            if (filterType === 'category' && page > 1) {
                return;
            }

            // We need to use the isLoadingOptions variable to block duplicated
            // requests on the InfiniteScroll component.
            setIsLoadingOptions(true);

            // Set and call the API url to get the options accordingly to the filter type
            let url = `${settings.apiUrl(auth.store.id, searchTerm)}&limit=${LIMIT}&page=${page}`
            const response = await api.get(url);

            // Map the fetched options
            let mappedOptions = settings.optionsMapper(response.data);

            // Set the options or aggregate them to existing ones based on the
            // requested page
            if (page === 1) {
                setOptions(mappedOptions)
            } else {
                setOptions([...options, ...mappedOptions]);
            }

            // Set the next page that is going to be requested
            setCurrentPage(page + 1);

            // In case we have already fetched all the options, or if we are dealing with
            // categories, set the hasMoreOptions variable to false
            if (filterType === 'category' || (LIMIT * page >= response.data.paging.total)) {
                setHasMoreOptions(false)
            }
        } catch (e) {
            console.log(e)
            toast.error(`Não foi possível carregar as opções disponíveis. Por favor, tente novamente mais tarde.`);
        } finally {
            setIsLoadingOptions(false);
        }
    }

    const search = () => {
        // If we are searching for a category filter on the frontend, since we
        // already have all the results
        if (filterType === 'category' && searchTerm) {
            const filteredOptions = options.filter(o =>
                o.breadcrumb && o.breadcrumb.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setOptions(filteredOptions);
        } else {
            setHasMoreOptions(true);
            setOptions([]);
            getOptions(1);
        }
    }

    const isOptionSelected = (option) => {
        return selectedOptions.some(o => o.id === option.id);
    }

    const save = async () => {
        const filter = {
            operation: filterOperation,
            type: filterType,
            [filterType]: selectedOptions
        }

        changeCampaignData({ filter })
        handleClose();
    }

    return (
        <Modal isOpen={isOpen} maxWidth={'85%'}>
            <section class="select-products">
                <main class="grid-x">
                    <section class="cell small-12 select-products__header">
                        <h1>{settings.header}</h1>
                        <i class="fas fa-x"></i>
                    </section>

                    <section class="cell small-12 large-6 select-products__options-list">
                        <h3>{settings.optionsTitle}</h3>

                        <div class="input-control">
                            <input
                                type="text"
                                placeholder={settings.searchInputPlaceholder}
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                }}
                            />

                            <button onClick={() => search()}>
                                <i><FaSearch /></i>
                                Buscar
                            </button>
                        </div>

                        <div className="select-products__options-list-content" ref={scrollRef}>
                            <InfiniteScroll
                                loadMore={() => {
                                    if (!isLoadingOptions) {
                                        getOptions(currentPage)
                                    }
                                }}
                                hasMore={hasMoreOptions}
                                loader={
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                                        <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
                                    </div>
                                }
                                useWindow={false}
                                getScrollParent={() => scrollRef.current}
                            >
                                <ul>
                                    {
                                        options.map(option => (
                                            <li class={isOptionSelected(option) ? 'selected' : ''}>
                                                <div class="option-name">
                                                    <span>{filterType === 'category' ? option.breadcrumb : option.name}</span>
                                                    {filterType === 'product' &&
                                                        <span>ID {option.id} {option.reference ? `${option.reference}` : ''}</span>
                                                    }
                                                </div>

                                                <div class="details-action">
                                                    {filterType === 'product' &&
                                                        <div>
                                                            <span>{formatMoney(Number(option.price))}</span>
                                                            <i class="fas fa-camera"></i>
                                                        </div>
                                                    }
                                                    {isOptionSelected(option) ? (
                                                        <button
                                                            class="red"
                                                            onClick={() => removeSelectedOption(option.id)}
                                                        >
                                                            Remover
                                                        </button>
                                                    ) : (
                                                        <button onClick={() => selectOption(option)}>
                                                            Selecionar
                                                        </button>

                                                    )}
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </InfiniteScroll>

                            {options.length === 0 && !hasMoreOptions &&
                                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', minHeight: 400 }}>
                                    <p>Sem opções disponíveis.</p>
                                </div>
                            }
                        </div>
                    </section>

                    <section class="cell small-12 large-6 select-products__selection-list">
                        <h3>{selectedOptions.length === 0 ? settings.emptySelectedOptionsTitle : settings.selectedOptionsTitle}</h3>
                        <ul>
                            {selectedOptions.map(selectedOption => (
                                <li>
                                    <span>{filterType === 'category' ? selectedOption.breadcrumb : selectedOption.name}</span>
                                    <button onClick={() => removeSelectedOption(selectedOption.id)}>
                                        <i><FaTrash /></i>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </section>

                    <section class="cell small-12 select-products__save">
                        <button disabled={isSaving} onClick={handleClose}>Cancelar</button>
                        <Button
                            disabled={false}
                            isSubmitting={isSaving}
                            submitingMessage="Salvando..."
                            onClick={save}
                        >
                            Confirmar
                        </Button>
                    </section>
                </main>
            </section >
        </Modal >
    );
}

export default FilterOptionsModal;
