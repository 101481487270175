/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { FaNetworkWired, FaPlay, FaPause } from "react-icons/fa";
import { toast } from "react-toastify";

import { api } from "../../services/api";
import { useAuth } from "../../hooks/useAuth";
import { PauseCampaign } from "../PauseCampaign";
import SpinnerBlueSvgGif from "../../assets/img/icons/spinner-blue.svg";
import { formatLongDate } from "../../utils/format";

function StatusSection({ campaign, changeCampaignData }) {
    const auth = useAuth();
    const [isActivatingCampaign, setIsActivatingCampaign] = useState(false);

    async function handleRestartCampaign() {
        setIsActivatingCampaign(true);
        if (
            auth.store === null ||
            !auth.store.google?.campaignId ||
            !auth.store.google?.googleAdsCustomerId
        )
            return;
        const campaignId = auth.store.google.campaignId;
        const customerId = auth.store.google.googleAdsCustomerId;

        try {
            await api.put(
                `/google/ads/customers/${customerId}/campaigns/${campaignId}`,
                {
                    status: "ENABLED",
                }
            );

            toast.success(`Campanha ativada com sucesso.`);
            changeCampaignData({ status: "ENABLED" });
        } catch {
        } finally {
            setIsActivatingCampaign(false);
        }
    }

    return (
        <section class="cell small-12 edit-campaign__status--section">
            <i><FaNetworkWired /></i>
            <h3>Status da campanha</h3>

            <span class="activation-date">Criada em {formatLongDate(campaign.startDate)}</span>

            {campaign.status === 'ENABLED' ? (
                <PauseCampaign
                    changeCampaignData={changeCampaignData}
                    element={
                        <div class="activation-control pause">
                            <i><FaPause /></i>
                            <span>Pausar campanha</span>
                        </div>
                    }
                />
            ) : (
                isActivatingCampaign ? (
                    <img src={SpinnerBlueSvgGif} alt="Animation spinner" height={25} width={25} />
                ) : (
                    <div class="activation-control activate" onClick={() => handleRestartCampaign()}>
                        <i><FaPlay /></i>
                        <span>Ativar campanha</span>
                    </div>
                )
            )}

            {campaign.status === 'ENABLED' &&
                <p class="status-alert">
                    <b>Atenção:</b> O algoritmo poderá levar até 4 semanas para aprender a otimizar sua campanha.
                    Caso ela seja manualmente pausada, este aprendizado será perdido.
                </p>
            }
        </section>
    );
}

export default StatusSection;
