import React, { useState } from "react";

import styles from "./styles.module.scss";

export const Youtube = ({
  videoId,
  title = "",
  width = "100%",
  height = "400px",
  imgSize = "mqdefault",
}) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <React.Fragment>
      {showVideo ? (
        <iframe
          title={title}
          width={width}
          height={height}
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&showinfo=0`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      ) : (
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${`https://img.youtube.com/vi/${videoId}/${imgSize}.jpg`})`,
            width,
            height,
          }}
          onClick={() => setShowVideo(true)}
        >
          <div className={styles.playButton} />
        </div>
      )}
    </React.Fragment>
  );
};
