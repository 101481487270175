import googleAdsIcon from "../../../../assets/img/icons/gray-google-ads.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import dashboardIcon from "../../../../assets/img/icons/dashboard.svg";

import { useFormSaver } from "../../../../hooks/useFormSaver";
import editIcon from "../../../../assets/img/icons/edit.svg";

import TipsImg from "../../../../assets/img/icons/tips.svg";
import { Tooltip } from "../../../../components/Tooltip";

import CurrencyInput from "react-currency-input-field";
import { formatMoney } from "../../../../utils/format";

import { Button } from "../../../../components/Button";
import { useAuth } from "../../../../hooks/useAuth";

import parentStyles from "../../styles.module.scss";
import { api } from "../../../../services/api";

import UpdateCampaign from "./updateCampaign";
import styles from "./styles.module.scss";

import { toast } from "react-toastify";
import { useState } from "react";

const ResumeStep = ({ reset, editPaymentAgain }) => {
  const auth = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [campaignCreated, setCampaignCreated] = useState(false);
  const history = useHistory();

  const [campaignCreationData] = useFormSaver(
    {
      name: "",
      amount: 0,
      dailyMoney: 0,
    },
    "tray-campaign-creation-data"
  );

  const [filter] = useFormSaver(
    {
      type: "default",
      operation: "allow",
    },
    "tray-campaign-creation-data-filter"
  );

  const createSmartShoppingCampaign = async () => {
    try {
      setIsSubmitting(true);

      await api.post(`google/ads/customers/${auth.googleAdsAccount?.id}/campaigns`, { dailyAmountInCents: campaignCreationData.dailyMoney, name: `PMax Tray ${campaignCreationData.name}` });

      auth.loadUser().then(() => {
        setCampaignCreated(true);
        // history.push({ pathname: "/", state: { showWelcomeModal: true } })
      });
    } catch (err) {
      if (err?.response?.data?.error?.code === "4105") {
        toast.error(`O nome da campanha é obrigatório.`);
      }

      if (err?.response?.data?.error?.code === "4107") {
        toast.error(`Já existe uma campanha cadastrada com este nome. Por favor, escolha um nome diferente.`);
      }

      if (err?.response?.data?.error?.code === "4113") {
        toast.error("Não foi possível criar uma campanha de Maior Desempenho na conta Google Ads selecionada.");
      }

      if (err?.response?.data?.error?.code === "4117") {
        toast.error(`Não foi possível criar a campanha. Verifique se o usuário do email ${auth.primaryEmail} possui permissão para criar campanhas na conta Google Ads ${auth.googleAdsAccount?.id}.`);
      }

      if (err?.response?.data?.error?.code === "4004" && err?.response?.data?.error?.error?.status === "PERMISSION_DENIED") {
        toast.error(`Houve um problema de permissão ao criar a campanha com a conta Google Ads vinculada. Por favor, verifique se a conta está ativa e habilitada, e tente novamente.`);
      }

      if (err?.response?.data?.error?.code === "4004" && err?.response?.data?.error?.error?.status !== "PERMISSION_DENIED") {
        toast.error(`Houve um problema ao criar a campanha com a conta Google Ads vinculada.`);
      }

      setIsSubmitting(false);
    } finally {
    }
  };

  function getFiltersDescription(filter) {
    if (!filter || !filter.operation || filter.type === 'default') {
        return 'Todos os produtos selecionados';
    }

    const count = Array.isArray(filter[filter.type]) ? filter[filter.type].length : 0;

    let type = '';
    switch (filter.type) {
        case 'brand': type = 'marcas'; break;
        case 'category': type = 'categorias'; break;
        case 'product': type = 'produtos'; break;
        default: break;
    }

    let operation = '';
    if (filter.operation === 'allow') {
        operation = type === 'produtos' ? 'selecionados' : 'selecionadas';
    }

    if (filter.operation === 'disallow') {
        operation = type === 'produtos' ? 'removidos' : 'removidas';
    }

    if (count === 1) {
        type = type.slice(0, -1);
        operation = operation.slice(0, -1);
    }

    return `${count} ${type} ${operation}`;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={parentStyles.box}>
          <div className={styles.campaign_header}>
            <img alt="" aria-hidden="true" src={dashboardIcon} />
            <strong>Campanha</strong>
          </div>

          <div className={styles.campaign_name_container}>
            <p>Nome da campanha</p>
            <strong>PMax - {campaignCreationData.name || ""}</strong>
          </div>

          <div className={parentStyles.line} />

          <div className={styles.investments_container}>
            <label forHtml="range">Investimento</label>

            <input className={`slider ${campaignCreationData.amount < 35 ? "low-level" : ""}`} id="range" type="range" value={campaignCreationData.amount} min="1" max="900" step="1" />

            <div className={styles.investment_value_container}>
              <div className={styles.daily_currency_container}>
                <span class={styles.currency_sign}>R$</span>
                <CurrencyInput
                  disabled
                  id="campaign__amountInvested--daily"
                  name="campaign__amountInvested--daily"
                  placeholder="Digite um valor"
                  value={campaignCreationData.amount}
                  decimalsLimit={2}
                  decimalScale={2}
                  step={1}
                  maxLength={900}
                  prefix=" "
                  intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                />
                <span className={styles.daily_text}>média&nbsp;diária</span>
                <Tooltip
                  id="campaign__invest__tooltip"
                  message="O algoritmo poderá investir mais em um dia quando identificar oportunidades de venda. O valor nunca será maior que o ‘orçamento mensal máximo’ ao lado."
                >
                  <img className={styles.question_mark} src={TipsImg} alt="Dicas" height={42} width={42} />
                </Tooltip>
              </div>

              <div className={styles.monthly_ammout}>
                Orçamento mensal: <strong>{formatMoney(campaignCreationData.amount * 30)}</strong>
              </div>
            </div>

            <div className={parentStyles.line} />

            <div className={styles.products_container}>
              <p>Produtos</p>

              <div>
                <strong>{getFiltersDescription(filter)}</strong>
                <div>{filter.operation === "allow" ? "Inserir" : "Restringir"}</div>
              </div>
            </div>

            <div className={parentStyles.line} />

            <button className={styles.edit_button} onClick={reset}>
              <img alt="" aria-hidden="true" src={editIcon} />
              Editar campanha
            </button>
          </div>
        </div>

        <div className={parentStyles.box} style={{ maxHeight: "250px" }}>
          <div className={styles.payment_header_container}>
            <img aria-hidden="true" alt="" src={googleAdsIcon} />
            <strong>Pagamento</strong>
          </div>

          <div className={styles.payment_info_container}>
            <div>
              <strong>Nome de perfil:</strong>
              <p>{`(${auth.user.name})`}</p>
            </div>

            <div>
              <strong>E-mail administrador:</strong>
              <p>{auth.primaryEmail}</p>
            </div>

            <div>
              <strong>ID da conta:</strong>
              <p>{auth.user.id}</p>
            </div>
          </div>

          <button className={styles.edit_button} onClick={editPaymentAgain} style={{ marginTop: "20px" }}>
            <img alt="" aria-hidden="true" src={editIcon} />
            Editar pagamento
          </button>
        </div>
      </div>

      <div className={parentStyles.line} />

      <div className={styles.complete_button_container}>
        <Button onClick={createSmartShoppingCampaign} isSubmitting={isSubmitting} submitingMessage="Criando campanha...">
          Publicar campanha
        </Button>

        {campaignCreated && (
          <UpdateCampaign
            endLoading={() => setIsSubmitting(true)}
            filter={filter}
            returnToCampaignPage={() => history.push({ pathname: "/campaign", state: { showWelcomeModal: true } })}
            campaignCreated={campaignCreated}
          />
        )}
      </div>
    </>
  );
};

export default ResumeStep;
