/* eslint-disable jsx-a11y/anchor-is-valid */
import { FaClipboardCheck } from "react-icons/fa";

function GeneralSection({ campaign, changeCampaignData }) {
    return (
        <section class="cell small-12 edit-campaign__general--section">
            <i><FaClipboardCheck /></i>
            <h3>Dados gerais</h3>
            <h4>Defina o nome da sua campanha.</h4>

            <form action="">
                <div>
                    <label for="campaign__name">Nome da campanha</label>
                    <input
                        type="text"
                        id="campaign__name"
                        value={campaign.name}
                        onChange={(e) => {
                            changeCampaignData({ name: e.target.value });
                        }}
                    />
                </div>

                <p class="notes">
                    Exemplo: Roupas de Inverno; Campanha Black Friday.
                </p>
            </form>
        </section>
    );
}

export default GeneralSection;
