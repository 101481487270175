/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import { Button } from "../Button";
import FilterOptionsModal from "./FilterOptionsModal";

function FiltersSection({ campaign, changeCampaignData }) {
    const [filter, setFilter] = useState({ type: 'default', operation: 'allow' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDisallowOperation, setIsDisallowOperation] = useState(false);

    useEffect(() => {
        if (campaign?.filter?.type) {
            setFilter(campaign.filter);
            setIsDisallowOperation(campaign?.filter?.operation === 'disallow')
        }
    }, [campaign]);

    const handleTypeChange = (e) => {
        changeCampaignData({ filter: { ...filter, type: e.target.value } });
    }

    const handleOperationChange = (operation) => {
        changeCampaignData({ filter: { type: 'default', operation } });
    }

    const getFilterTags = () => {
        const MAX_TAGS_NUMBER = 10;
        let tags = [];

        const values = filter[filter.type] || [];

        for (let index = 0; index < values.length; index++) {
            if (index === MAX_TAGS_NUMBER) break;

            const value = values[index];
            tags.push(<li>{value.name}</li>)
        }

        if (values.length > MAX_TAGS_NUMBER) {
            tags.push(<li>+{values.length - MAX_TAGS_NUMBER}</li>)
        }

        return tags;
    }

    return (
        <>
            <section class="cell small-12 edit-campaign__allow-products--section">
                <i><FaFileAlt /></i>
                <h3>Definir produtos para a campanha</h3>
                <h4>Selecione produtos, marcas ou categorias para incluir na campanha.</h4>

                <div>
                    <div class="campaign__filter-type">
                        <Button
                            class={!isDisallowOperation && 'selected'}
                            onClick={() => handleOperationChange('allow')}
                        >
                            Inserir
                        </Button>
                        <Button
                            class={isDisallowOperation && 'selected'}
                            onClick={() => handleOperationChange('disallow')}
                        >
                            Restringir
                        </Button>
                    </div>

                    {isDisallowOperation ? (
                        <div>
                            <label for="campaign__allow-products">Opções de restrição</label>
                            <select value={filter.type} onChange={handleTypeChange}>
                                <option value="default">Nenhuma restrição</option>
                                <option value="product">Restringir produtos</option>
                                <option value="brand">Restringir marcas</option>
                                <option value="category">Restringir categorias</option>
                            </select>

                            <ul class="edit-campaign__selected_label_list">
                                {getFilterTags()}
                            </ul>
                        </div>
                    ) : (
                        <div>
                            <label for="campaign__allow-products">Opções de seleção</label>
                            <select value={filter.type} onChange={handleTypeChange}>
                                <option value="default">Todos os produtos da loja</option>
                                <option value="product">Selecionar produtos</option>
                                <option value="brand">Selecionar marcas</option>
                                <option value="category">Selecionar categorias</option>
                            </select>

                            <ul class="edit-campaign__selected_label_list">
                                {getFilterTags()}
                            </ul>
                        </div>
                    )}
                </div>

                <p class="status-alert">
                    <b>Atenção:</b>  as campanhas com maior retorno são aquelas com grande quantidade de produtos.
                </p>

                {filter.type !== 'default' &&
                    <button onClick={() => setIsModalOpen(true)} type="button" class="edit-campaign__button blue">
                        Selecionar
                    </button>
                }
            </section>

            <FilterOptionsModal
                campaign={campaign}
                changeCampaignData={changeCampaignData}
                handleClose={() => setIsModalOpen(!isModalOpen)}
                isOpen={isModalOpen}
            />
        </>
    );
}

export default FiltersSection;
