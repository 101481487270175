import { AiFillCaretDown } from "react-icons/ai";
import { GrClose } from "react-icons/gr";

import { useState } from "react";
import "./styles.scss";

const DisconnectAccounts = ({ close, handleDisconnectAccount, loading }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selected, setSelected] = useState("paused");
  const [statusName, setStatusName] = useState("Pausado");

  const selectADropdownValue = (value, name) => {
    setSelected(value);
    setStatusName(name);
    setDropdownVisible(false);
  };

  return (
    <div className="disconnect-account-container">
      <div className="header">
        <p>Desconexão de Contas do Google</p>
        <GrClose color="#6B7280" className="close-icon" onClick={close} />
      </div>

      <div className="form-container">
        <p>
          Ao desconectar suas contas do Google vinculadas à plataforma Tray,
          fique tranquilo que suas campanhas no Google Ads permanecerão
          intactas. Apenas a campanha associada à plataforma Tray será afetada.
        </p>
        <p>
          Selecione o status que deseja para manter a campanha fora da
          plataforma Tray:
        </p>

        <div className="campaign-selector">
          <p>Campanha PMax Tray</p>

          <div
            className="dropdown-container"
            onClick={() => setDropdownVisible(!dropdownVisible)}
          >
            <div>{statusName}</div>
            <AiFillCaretDown className="arrow-down" />
          </div>

          {dropdownVisible && (
            <div className="dropdown">
              <div onClick={() => selectADropdownValue("paused", "Pausado")}>
                Pausado
              </div>
              <div onClick={() => selectADropdownValue("enabled", "Ativado")}>
                Ativado
              </div>
              <div onClick={() => selectADropdownValue("removed", "Removido")}>
                Removido
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="buttons-container">
        <button className="close-action-button" onClick={close}>
          Fechar
        </button>
        <button
          className="action-button-red"
          onClick={() => handleDisconnectAccount(selected) }
        >
          {loading ? (
            <>
              <div class="custom-loader"></div> Desconectando
            </>
          ) : (
            <>Desconectar contas</>
          )}
        </button>
      </div>
    </div>
  );
};

export default DisconnectAccounts;
