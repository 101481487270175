import { useEffect, useState } from "react";
import cx from "classnames";
import Helmet from "react-helmet";
import { ImCoinDollar } from "react-icons/im";
import { RiCursorLine, RiTruckFill } from "react-icons/ri";
import { MdCreditCard } from "react-icons/md";

import { Chart } from "../../components/Chart";
import { api } from "../../services/api";
import { formatConversions, formatMoney } from "../../utils/format";

import TipsImg from "../../assets/img/icons/tips.svg";
import Navigation from "../../components/Dashboard/Navigation";
import MerchantCenterProblems from "../../components/Dashboard/MerchantCenterProblems";
import RoasCard from "../../components/Dashboard/RoasCard";
import BestSellingProducts from "../../components/Dashboard/BestSellingProducts";
import WorstSellingProducts from "../../components/Dashboard/WorstSellingProducts";
import BudgetCard from "../../components/Dashboard/BudgetCard";
import { Tooltip } from "../../components/Tooltip";
import ProductsStats from "../../components/Dashboard/ProductsStats";
import HelpLink from "../../components/HelpLink";

function Normal() {
  const [generalStats, setGeneralStats] = useState({
    clicks: 0,
    cost: 0,
    conversions: 0,
    conversionsValue: 0,
    roas: 0,
  });

  const [lastDays, setLastDays] = useState("LAST_7_DAYS");

  useEffect(() => {
    async function getGeneralStats() {
      api
      .get("/google/reports/general_stats", {
        params: {
          dateRange: lastDays,
        },
      })
      .then((result) => {
        setGeneralStats(result.data);
      })
      .catch((err) => { });
    }

    getGeneralStats();
    
  }, [lastDays]);

  return (
    <>
      <Helmet>
        <title>Dashboard - Traygle</title>
        <meta name="description" content="Dashboard Traygle" />
        <meta property="og:title" content="Dashboard - Traygle" />
        <meta property="og:description" content="Dashboard Traygle" />
      </Helmet>

      <main role="main">
        <section className="grid-container">
          <div className="dashboard">
            <Navigation currentSection="main" />

            <section className="dashboard__visao--geral">
              <section className="dashboard__visao--geral__header">
                <h1>Visão Geral</h1>

                <aside className="dashboard__filters_buttons">
                  <button
                    className={cx("", {
                      active: lastDays === "LAST_7_DAYS",
                    })}
                    onClick={() => setLastDays("LAST_7_DAYS")}
                  >
                    7 Dias
                  </button>
                  <button
                    className={cx("", {
                      active: lastDays === "LAST_30_DAYS",
                    })}
                    onClick={() => setLastDays("LAST_30_DAYS")}
                  >
                    30 Dias
                  </button>
                </aside>
              </section>

              <main className="grid-x dashboard__infos">
                <section className="cell small-12 large-3 dashboard__v-geral__item clicks">
                  <aside className="fs-0">
                    <RiCursorLine size={22} />
                  </aside>

                  <main>
                    <h4>{generalStats?.clicks ?? 0}</h4>
                    <p>
                      Cliques{" "}
                      <Tooltip id="clicks" message={`Quantidade de cliques que seus produtos já tiveram nos últimos ${lastDays === 'LAST_7_DAYS' ? 7 : 30} dias`}>
                        <img src={TipsImg} alt="Dicas" />
                      </Tooltip>
                    </p>
                  </main>
                </section>

                <section className="cell small-12 large-3 dashboard__v-geral__item cost">
                  <aside className="fs-0">
                    <MdCreditCard size={22} />
                  </aside>

                  <main>
                    <h4>{formatMoney(generalStats?.cost)}</h4>
                    <p>Custo</p>
                  </main>
                </section>

                <section className="cell small-12 large-3 dashboard__v-geral__item conversions">
                  <aside className="fs-0">
                    <RiTruckFill size={20} />
                  </aside>

                  <main>
                    <h4>{formatConversions(generalStats?.conversions || 0)}</h4>
                    <p>Conversões</p>
                  </main>
                </section>

                <section className="cell small-12 large-3 dashboard__v-geral__item conversions-values">
                  <aside className="fs-0">
                    <ImCoinDollar size={20} />
                  </aside>

                  <main>
                    <h4>{formatMoney(generalStats?.conversionsValue)}</h4>
                    <p>Total vendido (R$)</p>
                  </main>
                </section>
              </main>
            </section>

            <section className="grid-x gap-1.5 dashboard--states-dash-payed">
              <aside className="cell small-12 large-4 maxW-309">
                <RoasCard roas={generalStats?.roas} />
                <BudgetCard />
              </aside>

              <Chart lastDays={lastDays} />

            </section>

            <section className="dashboard__products">
              <section className="dashboard__products--header">
                <h2>Produtos</h2>
              </section>

              <ProductsStats />
            </section>

            <section className="dashboard__products--items-problems">
              <section className="grid-x dashboard__products--items">
                <BestSellingProducts lastDays={lastDays} />
                <WorstSellingProducts lastDays={lastDays} />
              </section>

              <MerchantCenterProblems />
            </section>

            <HelpLink />
          </div>
        </section>
      </main>
    </>
  );
}

export default Normal;
