import { BsExclamationTriangle, BsExclamationCircle, BsQuestionCircle } from "react-icons/bs";
import NotFoundSvg from "../../assets/img/icons/Not found.svg";
import styles from "./styles.module.scss";

function MerchantCenterProblems({ merchantProblems = [] }) {
  const getIcon = (status) => {
    switch (status) {
      case "suggestion":
      case "unaffected":
        return <BsExclamationCircle size={16} color="#1ABC9C" />;
      case "error":
      case "demoted":
        return <BsExclamationTriangle size={16} color="#F7B84B" />;
      case "critical":
      case "disapproved":
        return <BsExclamationCircle size={16} color="#F1556C" />;
      default:
        break;
    }
  };

  return (
    <>
      <section class="cell small-12 problems__account--problems">
        {merchantProblems.length === 0 ? (
          <div className={styles.notFoundWrapper}>
            <img src={NotFoundSvg} alt="Not found spinner" />
            <h4>Nenhum problema encontrado</h4>
          </div>
        ) : (
          <ul className={styles.listMerchantProblems}>
            {merchantProblems.map((problem, index) => (
              <li key={`${problem.id}-${index}`}>
                {getIcon(problem.severity)}

                <p>{problem.title}</p>

                <a target="_blank" href={problem.documentation}>
                  <BsQuestionCircle size={14} color="#374151" />{" "}
                </a>
              </li>
            ))}
          </ul>
        )}
      </section>
    </>
  );
}

export default MerchantCenterProblems;
