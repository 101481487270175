import SpinnerBlueSvgGif from "../../../../assets/img/icons/spinner-blue.svg";
import googleAdsIcon from "../../../../assets/img/icons/gray-google-ads.svg";

import { FaExclamationCircle } from "react-icons/fa";
import parentStyles from "../../styles.module.scss";

import { GoLinkExternal } from "react-icons/go";
import { api } from "../../../../services/api";

import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { GoToCreateCampaignPage } from "../../../../utils/goToCreateCampaignPage";

const GoogleAdsStep = ({ auth, setStep, step }) => {
  // const [ isBillingApproved, setIsBillingApproved ] = useState(false);
  const [loading, setLoading] = useState(true);

  // const [existingCampaign, setExistingCampaign] = useState({});
  // const [showExistingCampaignModal, setShowExistingCampaignModal] = useState(false);

  // const [invitationLink, setInvitationLink] = useState("");
  // const [isSubmitting, setIsSubmitting] = useState(false);

  const [inviteIsAccepted, setInviteIsAccepted] = useState(false);

  async function validateInvite() {
    const originalInventationLink = await getInventationLink(auth.store.id);
    const googleAdsCustomerId = await getCustomerId(auth.store.id);

    const invitationData = await getInvitationStatus(auth, googleAdsCustomerId)
      .then((res) => res)
      .catch((err) => err);

    const ocid = originalInventationLink.split("ocid=")[1];

    if (!invitationData) return setInviteIsAccepted(true);
    const newInvitationLink = await sendNewInvitationLink(auth, invitationData.emailAddress, ocid);

    if (newInvitationLink.error || newInvitationLink.data === "") return setInviteIsAccepted(true);
  }

  async function getInventationLink(storeId) {
    try {
      const stores = await api.get(`platforms/tray/stores/${storeId}`);
      return stores.data?.google?.googleAdsInvitationLink;
    } catch (e) {
      return "";
    }
  }

  async function getInvitationStatus(auth, googleAdsCustomerId) {
    const res = await api.get(`platforms/tray/stores/${auth.store.id}/ads-invitation/${googleAdsCustomerId}`);
    const invitationData = res.data;

    if (!invitationData.results) return; // Return if invitation is accepted
    const lastInvitation = invitationData.results.length - 1;

    if (invitationData.results[lastInvitation].invitationStatus === "PENDING") return invitationData.results[lastInvitation].customerUserAccessInvitation;
    const resPending = await getInvitationPending(auth, googleAdsCustomerId);

    if (resPending.results && resPending.results[0]) return resPending.results[0].customerUserAccessInvitation;
    return invitationData.results[lastInvitation].customerUserAccessInvitation;
  }

  async function getInvitationPending(auth, googleAdsCustomerId) {
    const res = await api.get(`platforms/tray/stores/${auth.store.id}/ads-invitation/${googleAdsCustomerId}?status=PENDING`);
    return res.data;
  }

  async function sendNewInvitationLink(auth, email, ocid) {
    try {
      const res = await api.post(`platforms/tray/stores/${auth.store.id}/ads-invitation/${auth.store.google.googleAdsCustomerId}`, { email: email, ocid: ocid });
      const inviteLink = res.data;
      return { data: inviteLink };
    } catch (err) {
      if (err.response.data === "EMAIL_ADDRESS_ALREADY_HAS_ACCESS") return { error: err };
      return { data: "" };
    }
  }

  async function getCustomerId(storeId) {
    try {
      const res = await api.get(`platforms/tray/stores/${storeId}`);
      return res.data.google?.googleAdsCustomerId;
    } catch (err) {
      return "";
    }
  }

  useEffect(async () => {
    setLoading(true);
    await validateInvite();
    setLoading(false);

    // getCampaigns().then((campaigns) => {
    //   if (campaigns && campaigns[0] && campaigns[0].campaign) {
    //     setExistingCampaign(campaigns[0].campaign);
    //     setShowExistingCampaignModal(true);
    //   }
    // });

    // const storeResponse = await api.get(
    //   `platforms/tray/stores/${auth.store.id}`
    // );

    // await checkIfBillingIsApproved().then((isApproved) => {
    //   setIsBillingApproved(isApproved);
    // });

    // setInvitationLink(storeResponse?.data?.google?.googleAdsInvitationLink);
    // setLoading(false);
  }, []);

  const getCampaigns = async () => {
    const response = await api.get(`google/ads/customers/${auth.googleAdsAccount?.id}/campaigns?type=${auth.campaignType}`);
    return response?.data;
  };

  const checkIfBillingIsApproved = async () => {
    const response = await api.get(`google/ads/customers/${auth.googleAdsAccount?.id}/billing_setup`);

    return response?.data?.some((b) => b?.billingSetup?.status === "APPROVED" || b?.billingSetup?.status === "APPROVED_HELD");
  };

  // const openAndListenToInvitationLink = (adsWindowUrl) => {
  //   setIsSubmitting(true);

  //   const adsWindow = showGoogleBillingPopUp(adsWindowUrl);

  //   let currentAttempt = 0;
  //   const maxAttempts = 360; // 360 attempts x 5 seconds = 30 minutes

  //   const interval = setInterval(async () => {
  //     if (currentAttempt > maxAttempts) {
  //       setIsSubmitting(false);
  //       clearInterval(interval);
  //     }

  //     const isApproved = await checkIfBillingIsApproved();

  //     if (isApproved) {
  //       adsWindow.close();
  //       clearInterval(interval);
  //       setStep(2);
  //     }

  //     currentAttempt += 1;
  //   }, 5000);
  // }

  function showGoogleBillingPopUp(url) {
    // Calculate popUp size
    var h = Math.max(800, window.screen.availHeight * 0.66); // try to use 66% of height, but no smaller than 800
    var w = Math.max(500, window.screen.availWidth * 0.5); // try to use 25% of width, but no smaller than 800

    // Find popUp center
    var windowLocation = {
      left: window.screen.availLeft + window.screen.availWidth / 2 - w / 2,
      top: window.screen.availTop + window.screen.availHeight / 2 - h / 2,
    };

    const config =
      "ModalPopUp" +
      ", toolbar=no" +
      ", scrollbars=no," +
      ", location=no" +
      ", statusbar=no" +
      ", menubar=no" +
      ", resizable=0" +
      ", width=" +
      w +
      ", height=" +
      h +
      ", left=" +
      windowLocation.left +
      ", top=" +
      windowLocation.top;

    return window.open(url, "Billing", config);
  }

  async function handleGoogleAds() {
    setLoading(true);
    if (!inviteIsAccepted)
      return await GoToCreateCampaignPage(() => setStep(step + 1), auth).then(() => {
        setLoading(false);
      });
    showGoogleBillingPopUp("https://ads.google.com/aw/billing/summary");
    setLoading(false);
    setStep(step + 1);
  }

  return (
    <div className={styles.main_container}>
      <div className={styles.container}>
        <div className={parentStyles.box}>
          <img aria-hidden="true" alt="" src={googleAdsIcon} />

          <h4>Cobrança Google Ads</h4>

          {!loading && (
            <>
              <p>Para configurar os detalhes de cobrança você será direcionado para o Google Ads</p>

              {inviteIsAccepted && (
                <>
                  <div className={styles.google_ads_data_container}>
                    <strong>Conta Ads: </strong>
                    <p>{auth.user ? auth.user.name : ""}</p>
                    {`(${auth.googleAdsAccount ? auth.googleAdsAccount.id : ""})`}
                  </div>

                  <div className={styles.account_email_container}>
                    <strong>E-mail administrador: </strong>
                    <p>{auth.primaryEmail}</p>
                  </div>
                </>
              )}
            </>
          )}

          {loading ? (
            <div className="text-align-center">
              <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
            </div>
          ) : (
            <button className={styles.button} onClick={handleGoogleAds}>
              {inviteIsAccepted ? "Configurar cobrança" : "Validar Google Ads"}
              <GoLinkExternal size={20} style={{ marginLeft: "5px" }} />
            </button>
          )}

          <div className={styles.warn}>
            <FaExclamationCircle color="#008FFB" size={30} />
            <p>
              Para manter a segurança da conta, os usuários administrativos desta conta e da conta do gerente à qual ela está vinculada poderão ver quando e onde você acessou a conta do Google Ads e o
              método de autenticação que foi usado para acesso.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleAdsStep;
