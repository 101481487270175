import React, { useEffect, useState } from "react";
import nookies from "nookies";
import jwt from "jsonwebtoken";

import { useQuery } from "../hooks/useQuery";
import { api, setHeaderToken } from "../services/api";

import { wizardSteps } from "../contexts/WizardContext";

export const AuthContext = React.createContext({});

export function AuthProvider({ children }) {
  const query = useQuery();
  const [store, setStore] = useState(null);
  const [user, setUser] = useState(null);
  const [issues, setIssues] = useState({});
  const [productsStats, setProductsStats] = useState({});
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [merchantAccount, setMerchantAccount] = useState({});
  const [googleAdsAccount, setGoogleAdsAccount] = useState({});
  const [currentWizardStep, setCurrentWizardStep] = useState("");
  const [trayAdminUser, setTrayAdminUser] = useState(null);
  const [trayAdminUserId, setTrayAdminUserId] = useState(null);
  const [campaignType, setCampaignType] = useState("");
  const [campaignTypeName, setCampaignTypeName] = useState("");
  const [campaignTypeNamePlural, setCampaignTypeNamePlural] = useState("");
  const [allowMerchantCenterAccountCreation, setAllowMerchantCenterAccountCreation] = useState(false);
  const [isNewMerchantAccountCreated, setIsNewMerchantAccountCreated] = useState(false);
  const [isNewGoogleAdsAccountCreated, setIsNewGoogleAdsAccountCreated] = useState(false);
  const [traygleToken, setTraygleToken] = useState("");

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function loadAccountData() {
      if (merchantAccount && merchantAccount.id) {
        try {
          const response = await api.get(`google/merchant_center/accounts/${merchantAccount?.id}`);
          setUser(response.data);

          const userPrimaryEmail = response.data.users.filter((e) => e.admin);

          setMerchantAccount({
            id: response.data.id,
            name: response.data.name,
          });

          // If the current merchant account were created by the application,
          // we do not allow the creation of a new account
          if (merchantAccount.id === store.google?.merchantId && store.google?.merchantCenterAccountOrigin === "created") {
            setIsNewMerchantAccountCreated(true);
          }

          if (userPrimaryEmail.length > 0) {
            // We can have other admin users that are not the current logged Google user. So we
            // need to check if the current logged Google user is on the userPrimaryEmail list
            // before taking the first user email.
            setPrimaryEmail(userPrimaryEmail.some((u) => u.emailAddress === primaryEmail) ? primaryEmail : userPrimaryEmail[0].emailAddress);
          }
        } catch {}
      }
    }
    loadAccountData();
  }, [merchantAccount?.id]);

  async function loadUser(token = null) {
    try {
      if (!token) {
        token = query.get("at");
      }

      if (token) {
        setTraygleToken(token);
        nookies.set(null, "@Traygle:token", token, {
          maxAge: 60 * 60 * 24,
        });
      } else {
        token = nookies.get(null)[`@Traygle:token`] || traygleToken;
      }

      if (token) {
        const decoded = jwt.decode(token);
        api.defaults.headers.common = {
          Authorization: `Bearer ${token}`,
        };
        setHeaderToken(token);

        if (decoded?.trayAdminUser) {
          setTrayAdminUser(decoded?.trayAdminUser);
        }

        if (decoded?.trayAdminUserId) {
          setTrayAdminUserId(decoded?.trayAdminUserId);
        }

        const storeResponse = await api.get(`platforms/tray/stores/${decoded.storeId}`);
        setStore(storeResponse.data);

        if (storeResponse?.data?.currentWizardStep === wizardSteps.COMPLETED) {
          try {
            const [issuesResponse, statsResponse] = await Promise.all([api.get("/google/reports/issues"), api.get("/google/reports/products/stats")]);
            if (issuesResponse.data) setIssues(issuesResponse.data);
            if (statsResponse.data) setProductsStats(statsResponse.data);
          } catch {}
        }

        const userPrimaryEmail = storeResponse.data?.google?.email;
        if (userPrimaryEmail?.length > 0) {
          setPrimaryEmail(userPrimaryEmail);
        }

        const merchantId = storeResponse.data?.google?.merchantId;
        if (merchantId?.length > 0) {
          setMerchantAccount({
            id: merchantId,
          });
        }

        const googleAdsCustomerId = storeResponse.data?.google?.googleAdsCustomerId;
        if (googleAdsCustomerId?.length > 0) {
          setGoogleAdsAccount({
            id: googleAdsCustomerId,
          });
        }

        const wizardStep = storeResponse.data?.currentWizardStep;
        if (wizardStep?.length > 0) {
          setCurrentWizardStep(wizardStep);
        }

        //Datalayer preparation that came from url
        //try to get the values from localstorage, otherwise get from the url
        const dataLayerKey = "@Traygle:datalayer";
        let dataLayer = localStorage.getItem(dataLayerKey);

        if (!dataLayer || query.get("trayemail")) {
          dataLayer = {
            trayEmailFromUrl: query.get("trayemail"),
            googleEmailFromUrl: query.get("googleemail") || userPrimaryEmail,
            googleAdsCustomerIdFromUrl: query.get("googleadscustomerid") || googleAdsCustomerId,
            merchantIdFromUrl: query.get("merchantid") || merchantId,
            storeIdFromUrl: query.get("storeid") || decoded.storeId || store.id,
          };

          localStorage.setItem(dataLayerKey, JSON.stringify(dataLayer));
        } else {
          dataLayer = JSON.parse(dataLayer);
        }

        //Set a timeout to prevent the datalayer to be sent before the user is fully loaded and trackings are ready
        setTimeout(() => {
          pushToDataLayer(dataLayer);
        }, 5000);
        //End datalayer preparation

        const storeCampaignType = storeResponse.data?.google?.campaignType;
        if (storeCampaignType === "pmax") {
          setCampaignType("pmax");
          setCampaignTypeName("campanha de Maior Desempenho");
          setCampaignTypeNamePlural("campanhas de Maior Desempenho");
        } else {
          setCampaignType("ssc");
          setCampaignTypeName("campanha Inteligente");
          setCampaignTypeNamePlural("campanhas Inteligentes");
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  function pushToDataLayer(datalayerObject) {
    const { trayEmailFromUrl, googleEmailFromUrl, googleAdsCustomerIdFromUrl, merchantIdFromUrl, storeIdFromUrl } = datalayerObject;

    // Populate the Data Layer with pageview information
    if (trayEmailFromUrl && trayEmailFromUrl !== "roottray") {
      window.dataLayer.push({
        event: "gads_login",
        storeId: storeIdFromUrl,
        userEmail: trayEmailFromUrl,
        googleEmailAccount: googleEmailFromUrl,
        googleAdsId: googleAdsCustomerIdFromUrl,
        googleMechantId: merchantIdFromUrl,
      });
    }
  }

  function changeMerchantAccount(account) {
    setMerchantAccount(account);
  }

  function changeGoogleAdsAccount(account) {
    setGoogleAdsAccount(account);
  }

  const values = {
    issues,
    productsStats,
    store,
    user,
    hasUser: !!user,
    primaryEmail,
    currentWizardStep,
    trayAdminUser,
    trayAdminUserId,

    campaignType,
    campaignTypeName,
    campaignTypeNamePlural,

    loadUser,

    merchantAccount,
    changeMerchantAccount,

    googleAdsAccount,
    changeGoogleAdsAccount,

    allowMerchantCenterAccountCreation,
    setAllowMerchantCenterAccountCreation,

    isNewMerchantAccountCreated,
    setIsNewMerchantAccountCreated,

    isNewGoogleAdsAccountCreated,
    setIsNewGoogleAdsAccountCreated,

    traygleToken,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}
