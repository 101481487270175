import { Redirect, Route, Switch } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import Reports from "../pages/Dashboard/Reports";
import Problems from "../pages/Dashboard/Problems";
import Settings from "../pages/Dashboard/Settings";

import Campaign from "../pages/Dashboard/Campaign";
import Wizard from "../pages/Wizard";
import EditCampaign from "../pages/Dashboard/EditCampaign";
import { useAuth } from "../hooks/useAuth";

import Pmax from "../pages/Pmax";

function Routes() {
  const isWizard = window.location.pathname === '/wizard';
  const auth = useAuth();
  const hasCampaign = auth.store?.google?.campaignId;

  const isHomeWithoutCampaign = !hasCampaign &&  window.location.pathname === '/';

  return (
    <>
      <main role="main">
        <section 
          className={`${!isWizard || !isHomeWithoutCampaign && 'grid-container'}`}
        >
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/reports" component={Reports} />
            <Route path="/problems" component={Problems} />
            <Route path="/settings" component={Settings} />
            <Route path="/campaigns" component={EditCampaign} />
            <Route path="/wizard" exact component={Wizard} />
            <Route path="/pmax" component={Pmax} />
            <Route path="/campaign" component={ Campaign } />
            <Redirect to="/" />
          </Switch>
        </section>
      </main>
    </>
  );
}

export default Routes;
